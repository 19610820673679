import { createSlice } from '@reduxjs/toolkit';
import LinkModel from 'Models/Link/LinkModel.interface';
import { useAppDispatch, useAppSelector } from 'app/store';
import { fetchCurrentPageAsync } from './currentPage';

export interface CurrentPage {
  isLoading: boolean;
  pageId: string;
  url: string;
  pageType: string;
  pageTitle: string;
  breadcrumb: LinkModel[];
  alternativeLinks?: Record<string, string>;
}

export type GenericPageState<T extends CurrentPage = CurrentPage> = T;

const initialState: GenericPageState = {
  isLoading: false,
  pageId: '',
  url: '/',
  pageType: 'StartPage',
  pageTitle: '',
  breadcrumb: [],
};

const currentPageSlice = createSlice({
  name: 'currentPage',
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      const result = action.payload;

      if (result && result.data) {
        return {
          ...result.data,
          isLoading: false,
        };
      }
      return { ...state, isLoading: false };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentPageAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCurrentPageAsync.fulfilled, (state, action) => {
      const result = action.payload;

      if (result && result.data) {
        return {
          ...(result.data as CurrentPage),
          isLoading: false,
        };
      }
      return { ...state, isLoading: false };
    });
  },
});

export default currentPageSlice.reducer;
export const useCurrentPage = <T extends CurrentPage>() =>
  useAppSelector((state) => state.currentPage) as T;

export const useFetchCurrentPage = () => {
  const dispatch = useAppDispatch();

  return () => dispatch(fetchCurrentPageAsync());
};

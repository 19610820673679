// Import necessary libraries and utilities for managing global settings in the Redux store
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { firstLetterLower } from 'app/helpers/stringHelper';
import { patchModel } from 'app/shared/utils/patchModel';
import {
  AppDispatch,
  RootState,
  plainState,
  useAppDispatch,
  useAppSelector,
} from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'Kex/KexPage';
import { ObjectKeys } from '../utils/utils';
import { fetchGlobalStateAsync } from './globalSettings';
import { priceHelpers } from './priceHelpers';
import ImageModel from 'Models/Assets/ImageModel.interface';
import { FeatureManagement } from 'app/shared/globalSettings/FeatureManagement';

export interface StaticPage {
  pageType: string; // Type of page to link to
  url: string; // Navigation target for the page
  title: string; // Text to display on the site};
}

export interface CountriesModel {
  url: string;
  flagImage: ImageModel;
  country: string;
  language: string;
  currency: string;
  countryCode: string;
  active: boolean;
}

export interface CountriesModel {
  url: string;
  flagImage: ImageModel;
  country: string;
  language: string;
  currency: string;
  countryCode: string;
  active: boolean;
}

export type StaticPages = { [key: string]: StaticPage };
/**
 * Interface defining the structure and type of the global settings state.
 */
export interface GlobalSettingsState {
  currency: string; // Currency type (e.g., 'USD', 'SEK')
  market: string; // Market type (e.g., 'US', 'SE')
  marketThreeLetterCode: string; // Market type in three letters (e.g., 'USA', 'SWE')
  languageRoute: string; // Language route for the application (e.g., 'en', 'sv')
  gtmId?: string; // Google Tag Manager ID (optional)
  insightKey?: string; // Application Insight Instrumentation Key (optional)
  staticPages: StaticPages; // Key-value pairs representing static pages, useful for error pages like 404
  priceInclVAT: boolean; // Flag to show prices with VAT included
  currentCountry: string;
  countries: CountriesModel[];
  featureManagement: { [key in FeatureManagement]: boolean };
  minOrderValue?: number;
}

/**
 * Initial state for global settings.
 */
export const initialState: GlobalSettingsState = {
  market: '',
  marketThreeLetterCode: '',
  currency: '',
  languageRoute: 'sv',
  staticPages: {},
  currentCountry: 'SE',
  countries: [],
  priceInclVAT: false,
  minOrderValue: 30000,
  featureManagement: {
    showCart: true,
    showCountrySelector: false,
    showEmailCart: false,
    showFavorites: false,
    showLogin: true,
    showThemeToggle: false,
    showVatToggle: false,
    showQuickOrder: true,
    showCSVExport: true,
    showSaveCart: true,
  },
};

/**
 * Redux slice managing global settings within the app.
 */
export const globalSettingsSlice = createSlice({
  name: 'globalSettigs', // Unique name for this slice
  initialState, // Setting the initial state
  reducers: {
    /**
     * Reducer to update the global settings state with new values.
     */
    updateGlobalState: (state, action: PayloadAction<GlobalSettingsState>) => {
      return patchModel(state, action.payload); // Utility to merge new values into the state
    },
    togglePriceInclVAT: (state) => {
      state.priceInclVAT = !state.priceInclVAT;
    },
  },
  extraReducers: (builder) => {
    /**
     * Handle the successful completion of fetchGlobalStateAsync by updating the state.
     */
    builder
      .addCase(fetchGlobalStateAsync.fulfilled, (state, action) => {
        const result = action.payload;

        if (result?.isSuccess) {
          plainState.set('language', result.data.languageRoute);
          const staticPages: StaticPages = {};
          if (result.data.staticPages) {
            ObjectKeys(result.data.staticPages).forEach((key) => {
              staticPages[
                firstLetterLower(result.data.staticPages[key].value.pageType)
              ] = {
                ...result.data.staticPages[key].value,
              };
            });
          }
          staticPages.errorPage &&
            plainState.set(500, staticPages.errorPage.url);
          staticPages.notFoundPage &&
            plainState.set(404, staticPages.notFoundPage.url);

          return {
            ...state,
            ...result.data,
            languageRoute:
              result.data.languageRoute !== ''
                ? result.data.languageRoute
                : initialState.languageRoute,
            featureManagement: initialState.featureManagement,
            staticPages,
          };
        }

        return {
          ...state,
        };
      })
      .addCase(fetchGlobalStateAsync.rejected, (state, action) => {
        // eslint-disable-next-line
        console.error('Failed to fetch global settings', action.error);
      });
  },
});

// Destructure the updateGlobalState action for external use
const { updateGlobalState, togglePriceInclVAT } = globalSettingsSlice.actions;

/**
 * Custom hook to get the current global settings state from the Redux store.
 */
const useGlobalSettings = () =>
  useSelector((state: RootState) => state && state.globalSettings);

/**
 * Custom hook to dispatch actions related to global settings.
 */
export const useGlobalSettingsDispatch = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { staticPages } = useGlobalSettings();
  const navigate = useNavigate();
  const onError = () => {
    navigate(staticPages.errorPage.url);
  };
  /**
   * Fetch the latest global settings.
   */
  const fetchGlobalState = () => {
    dispatch(fetchGlobalStateAsync({ error: onError }));
  };
  return { fetchGlobalState };
};

export const useTogglePriceInclVAT = () => {
  const dispatch = useAppDispatch();
  return () => dispatch(togglePriceInclVAT());
};

const usePrice = () => {
  const priceInclVAT = useAppSelector(
    (state: RootState) => state.globalSettings.priceInclVAT
  );

  return {
    priceInclVAT,
    getPriceModel: priceHelpers.getPriceModel(priceInclVAT),
    getRegularPriceModel: priceHelpers.getRegularPriceModel(priceInclVAT),
    getDiscountModel: priceHelpers.getDiscountModel(priceInclVAT),
    emptyPrice: priceHelpers.emptyPrice,
    getPrice: priceHelpers.getPrice(priceInclVAT),
    getRegularPrice: priceHelpers.getRegularPrice(priceInclVAT),
    getDiscount: priceHelpers.getDiscount(priceInclVAT),
  };
};

// Export necessary elements for external use
export { updateGlobalState, togglePriceInclVAT, useGlobalSettings, usePrice };

// Export the reducer for setting up the Redux store
export default globalSettingsSlice.reducer;

import themes from 'DesignSystem/StyledComponets/Theme/Theme';
import GlobalStyles, {
  FontFamilies,
} from 'DesignSystem/StyledComponets/global.styled';
import { canUseDOM } from 'app/helpers/windowHelper';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ThemeProvider as Provider } from 'styled-components';
import { EventDispatcher, SET_THEME } from 'shared/utils/EventDispatcher';

type PropType = {
  theme?: ThemeKey;
  children: React.ReactNode;
};

const loadableThemes = {
  dark: themes.dark,
  light: themes.light,
};

type ThemeKey = keyof typeof loadableThemes | '';

const isThemeKey = (key: string | null): key is ThemeKey =>
  key === '' || (!!key && Object.keys(loadableThemes).includes(key));

type ThemeType = {
  theme?: ThemeKey;
};

const ThemeContext = React.createContext<ThemeType>({} as ThemeType);

export const ThemeProvider = ({ children }: PropType) => {
  const [siteTheme, setTheme] = useState<ThemeKey>(getTheme() || 'light');

  const onThemeSwitch = () => {
    storeTheme(siteTheme === 'light' ? 'dark' : 'light', setTheme);
  };

  useEffect(() => {
    EventDispatcher.subscribe(SET_THEME, onThemeSwitch);

    return () => {
      EventDispatcher.unsubscribe(SET_THEME, onThemeSwitch);
    };
  });

  return (
    <Provider theme={themes[siteTheme as keyof typeof themes]}>
      <FontFamilies />
      <GlobalStyles />
      {children}
    </Provider>
  );
};

const storeTheme = (
  theme: ThemeKey,
  callback: Dispatch<SetStateAction<ThemeKey>>,
  baseTheme?: ThemeKey
) => {
  if (baseTheme) {
    const storedTheme = localStorage.getItem('theme');

    if (isThemeKey(storedTheme)) {
      callback(
        baseTheme !== storedTheme && storedTheme ? storedTheme : baseTheme
      );
    }

    return;
  }
  localStorage.setItem('theme', theme);
  callback(theme);
};

export const getTheme = (): ThemeKey => {
  if (canUseDOM() && window.localStorage) {
    const theme = localStorage.getItem('theme');

    if (isThemeKey(theme)) {
      return theme;
    }
  }
  return 'light';
};

export const useTheme = (): ThemeType => {
  return useContext(ThemeContext) as ThemeType;
};

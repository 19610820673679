export default class KeyValueStore<T extends object> {
  private state: Partial<T>;
  public readonly subscriptions: Partial<Record<keyof T, (() => void)[]>>;

  constructor(state?: Partial<T>) {
    this.state = state ?? {};
    this.subscriptions = {};
  }

  set<N extends keyof T, V extends T[N]>(key: N, value: V) {
    this.state[key] = value;
    this.triggerSubscriptions(key);
  }

  // Can return undefined
  get<N extends keyof T>(key: N) {
    return this.state[key];
  }

  // Can return undefined and type is lying to you
  getUnsafeValue<N extends keyof T>(key: N) {
    return this.state[key] as T[N];
  }

  // Can never return undefined since you provide a fallback value
  getValue<N extends keyof T, V extends T[N]>(key: N, fallbackValue: V) {
    return this.state[key] ?? fallbackValue;
  }

  subscribe<N extends keyof T>(key: N, callback: () => void) {
    if (!this.subscriptions[key]) this.subscriptions[key] = [];
    this.subscriptions[key]?.push(callback);
  }

  unsubscribe<N extends keyof T>(key: N, callback: () => void) {
    this.subscriptions[key] = this.subscriptions[key]?.filter(
      (subscription) => subscription !== callback
    );
  }

  triggerSubscriptions<N extends keyof T>(key: N) {
    this.subscriptions[key]?.forEach((subscription) => subscription());
  }
}

export enum HttpStatusCode {
  // Successful Responses
  Ok = 200,
  Created = 201,
  NoContent = 204,

  // Redirection Messages
  MovedPermanently = 301,
  Found = 302,

  // Client Error Responses
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  MethodNotAllowed = 405,

  // Server Error Responses
  InternalServerError = 500,
  ServiceUnavailable = 503,
}

// Importing necessary libraries and utilities
import { createSlice } from '@reduxjs/toolkit';
import { IconName } from 'DesignSystem/Icons/KexIconLoader';
import ImageModel from 'Models/Assets/ImageModel.interface';
import NavigationLinksRenderingOptionsEnum from 'Models/Headers/Navigation/NavigationLinksRenderingOptionsEnum.interface';
import { RootState, useAppDispatch } from 'app/store';
import { useSelector } from 'react-redux';
import { fetchNavigationStateAsync } from './navigation';

export interface NavigationLink {
  type: string; // Type of page to link to
  image?: ImageModel; // Use to preview categoies
  iconName?: IconName; // Used to display icon for category
  id: string; // A guid or other string id,
  url: string; // Navigation target for the page
  title: string; // Text to display on the site
}

export interface NavigationStructure extends NavigationLink {
  renderingOption: NavigationLinksRenderingOptionsEnum; // Describes how to render the link
  children?: NavigationStructure[]; // //Text to display on the site
}

export interface SiteStructureResponse {
  root: NavigationLink;
  mainNavigation: NavigationStructure[];
}

// Initial state for the global settings
export const initialState: SiteStructureResponse = {
  mainNavigation: [],
  root: {} as NavigationLink,
} as SiteStructureResponse;

// Redux slice for global settings
export const navigationSlice = createSlice({
  name: 'navigation', // Name of the slice
  initialState, // Initial state for this slice
  reducers: {},
  extraReducers: (builder) => {
    // Handling the fulfilled state of fetchNavigationStateAsync
    builder.addCase(fetchNavigationStateAsync.fulfilled, (state, action) => {
      const result = action.payload;

      if (result?.isSuccess) {
        return { ...state, ...result.data };
      }
      return state;
    });
  },
});

export const useFetchNavigation = () => {
  const dispatch = useAppDispatch();

  function fetchNavigationState() {
    dispatch(fetchNavigationStateAsync());
  }
  return {
    fetchNavigationState,
  };
};

export const useSiteNavigation = () =>
  useSelector((state: RootState) => state.navigation);

// Exporting the reducer as the default export
export default navigationSlice.reducer;

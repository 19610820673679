import FacetModel from 'app/shared/filter/models/Facet';
import { getUrlParameter } from './urlHelper';

export const FILTER_URL_CONSTANTS = {
  FILTER: 'filter::',
  SEARCH_QUERY: 'query',
  SELECTED_MODEL_FILTER: 'selectedModel',
  ITEMS: 'take',
  DEFAULT_ITEMS: 24,
  ORDER: 'order',
  IN_STOCK: 'instock',
};

export const takeParameter: [string, string][] = [
  ['take', FILTER_URL_CONSTANTS.DEFAULT_ITEMS.toString()],
];

export const merge = (
  fullFacets?: FacetModel[],
  filteredFacets?: FacetModel[]
): typeof fullFacets => {
  if (!fullFacets) return undefined;

  const mp = new Map(filteredFacets?.map((k) => [k.name, k] as const));

  return fullFacets
    .map((facet) => {
      const facetFilter = mp.get(facet.name);

      const filtered = new Map(
        facetFilter?.terms?.map((term) => [term.key, term.count])
      );

      const terms = facet.terms?.map((term) => {
        const count = filtered.get(term.key) || 0;
        return { ...term, count };
      });

      if (!terms?.length && facet.terms?.length) {
        return undefined;
      }

      return { ...facet, terms };
    })
    .filter((f): f is FacetModel => !!f);
};

export const getSearchQueryParameter = () => {
  return getUrlParameter(FILTER_URL_CONSTANTS.SEARCH_QUERY);
};

export const getSelectedModel = () => {
  return getUrlParameter(FILTER_URL_CONSTANTS.SELECTED_MODEL_FILTER);
};

import ProductCardSkeleton from 'Commerce/Molecules/Card/ProductCard/ProductCardSkeleton';
import ContentContainer from 'DesignComponents/Molecules/ContentContainer/ContentContainer';
import SkeletonLoader from 'DesignComponents/Organisms/SkeletonLoader/SkeletonLoader';
import styled from 'styled-components';

function LoadingPageType() {
  return (
    <main id="maincontent">
      <ContentContainer>
        <SkeletonLoader>
          <Hero className="skeletonLoader" />
          <CardList>
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
          </CardList>
          <Content className="skeletonLoader" />
        </SkeletonLoader>
      </ContentContainer>
    </main>
  );
}

const Hero = styled.section`
  height: 30rem;
  width: 100%;
  margin: 2rem 0;
`;

const CardList = styled.section`
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
`;

const Content = styled.section`
  height: 30rem;
  width: 50%;

  margin: 2rem auto 2rem 0;
`;

export default LoadingPageType;

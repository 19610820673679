import { createSlice } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from 'app/store';
import { VariationCodeSearch } from '../../../Commerce/Pages/QuickOrder/QuickOrder';
import {
  ResultItemProp,
  ResultItemsProps,
} from 'Commerce/Molecules/QuickOrder/ResultItem';

// Setting default values for the code search state
export const initialState: ResultItemsProps = {
  results: [] as ResultItemProp[],
  isSearching: false,
  numberOfHits: 0,
};

/**
 * Slice for managing quick-order operations in the Redux store.
 */
export const codeSearchSlice = createSlice({
  name: 'codeSearch',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(VariationCodeSearch.fulfilled, (state, action) => {
      if (!action.payload) return;
      const result = action.payload;
      if (result?.isSuccess) {
        state.results = result.data.results;
        state.numberOfHits = result.data.numberOfHits;
        state.isSearching = false;
      }
    });
    builder.addCase(VariationCodeSearch.rejected, (state) => {
      state.isSearching = false;
    });
    builder.addCase(VariationCodeSearch.pending, (state) => {
      return {
        ...state,
        isSearching: true,
      };
    });
  },
});

export const useCodeSearch = () => {
  const dispatch = useAppDispatch();
  return (query: string) => dispatch(VariationCodeSearch(query));
};
export default codeSearchSlice.reducer;
export const useCodeSearchResults = () =>
  useAppSelector((state) => state.codeSearch);

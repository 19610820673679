import SkeletonLoader from 'DesignComponents/Organisms/SkeletonLoader/SkeletonLoader';
import styled from 'styled-components';

const ProductCardSkeleton = () => {
  return (
    <SkeletonLoader>
      <Card>
        <ProductImageWrapper className="skeletonLoader" />
        <Title className="skeletonLoader" />
        <Price className="skeletonLoader" />
      </Card>
    </SkeletonLoader>
  );
};

export default ProductCardSkeleton;

const ProductImageWrapper = styled.div`
  height: 19rem;
  width: 19rem;
  margin-bottom: ${({ theme }) => theme.space.s100};
`;
const Title = styled.div`
  width: 2.25rem;
  height: 1.5rem;
  margin-bottom: ${({ theme }) => theme.space.s100};
`;

const Price = styled.div`
  width: 3rem;
  height: 1.5rem;
  margin-bottom: ${({ theme }) => theme.space.s100};
`;
const Card = styled.div``;

import { useGlobalSettings } from 'app/shared/globalSettings/globalSettingsSlice';
import { useEffect } from 'react';
import { canUseDOM } from 'app/helpers/windowHelper';
import { AsString } from 'shared/types/types';

export const useInsertGtmScript = () => {
  const { gtmId } = useGlobalSettings();

  useEffect(() => {
    if (gtmId) {
      const script = document.createElement('script');
      script.innerHTML =
        gtmId && script
          ? `
    (function(w,d,s,l,i){
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });
      var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s), dl = l !== 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '${gtmId}');
  `
          : '';

      document.head.appendChild(script);
    }
  }, [gtmId]);
};

export type GTMEventName =
  | 'virtual_pageview'
  | 'view_item'
  | 'add_to_cart'
  | 'add_reorder_to_cart'
  | 'begin_checkout'
  | 'add_payment_info'
  | 'purchase'
  | 'view_item_list'
  | 'begin_checkout'
  | 'view_cart'
  | 'product_filter'
  | 'fast_filter'
  | 'remove_from_cart'
  | 'add_to_wishlist'
  | 'fast_filter'
  | 'product_filter';
export type GTMEvent = AsString<GTMEventName>;

// Only allow defined key names (GTMEventName) in name of event
/*eslint @typescript-eslint/no-explicit-any: ["off", { "ignoreRestArgs": true }]*/
export const pushEventToGTM = (event: GTMEventName, payload: any) => {
  const dataObj = payload ?? {};
  dataObj.event = event;

  pushToGTM(dataObj);
};

// Allow any key name in payload here. This function should be avoided since it's not type safe on event name
export const pushToGTM = (payload: Partial<Record<GTMEvent, unknown>>) => {
  const dataLayer = canUseDOM() ? window.dataLayer || [] : [];
  dataLayer.push(payload);
};

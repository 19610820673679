// Importing necessary libraries and utilities
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import OrderModel, { OrderModelBase } from '../order/OrderModel';
import { fetchAllOrders, fetchOrder } from '../order/order';

export interface OrderHistoryState {
  orders: OrderModelBase[];
  orderDetails?: OrderModel[];
  isLoading: boolean;
  totalOrderCount?: number;
}

const initialState: OrderHistoryState = {
  orders: [],
  totalOrderCount: 0,
  isLoading: false,
};
// Redux slice for global settings
export const orderHistorySlice = createSlice({
  name: 'orderHistory', // Name of the slice
  initialState, // Initial state for this slice
  reducers: {},
  extraReducers: (builder) => {
    // Handling the fulfilled state of fetchNavigationStateAsync
    builder.addCase(fetchAllOrders.fulfilled, (state, action) => {
      const result = action.payload;

      if (result?.isSuccess) {
        return {
          ...state,
          orders: result.data.orders,
          isLoading: false,
          totalOrderCount: result.data.totalOrderCount,
        };
      }
      return { ...state, orders: [], isLoading: false };
    });
    builder.addCase(fetchAllOrders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllOrders.rejected, (state) => {
      state.isLoading = false;
      state.orders = [];
    });
    builder.addCase(fetchOrder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchOrder.fulfilled, (state, action) => {
      const result = action.payload;
      if (result?.isSuccess) {
        const details = state.orderDetails ? [...state.orderDetails] : [];
        if (details) {
          if (details.find((o) => o.orderNumber === result.data?.orderNumber)) {
            details.map((o) => {
              if (o.orderNumber === result.data?.orderNumber) {
                return result.data;
              }
              return o;
            });
          } else {
            details.push(result.data);
          }
        }
        return { ...state, orderDetails: details, isLoading: false };
      }
      return { ...state, isLoading: false };
    });
  },
});

export const useOrderHistory = () =>
  useSelector((state: RootState) => state.orderHistory);

export const useOrderDetail = (orderNumber: string) => {
  const [orderDetail, setOrderDetail] = useState<OrderModel | undefined>(
    undefined
  );
  const { orderDetails } = useOrderHistory();

  useEffect(() => {
    if (orderDetails && orderNumber) {
      setOrderDetail(orderDetails.find((o) => o.orderNumber === orderNumber));
    }
  }, [orderDetails]);

  useEffect(() => {
    if (!orderNumber) {
      setOrderDetail(undefined);
    }
  }, [orderNumber]);

  return orderDetail;
};
// Exporting the reducer as the default export
export default orderHistorySlice.reducer;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from 'app/store';
import { QuickSearchResult } from 'shared/search/models/searchModels';
import {
  fetchQuickSearchResult,
  fetchSuggestionResult,
} from '../api/quickSearch';

export interface SearchState {
  query: string;
  isLoading?: boolean;
  result?: QuickSearchResult;
  suggestions?: string[];
  didYouMean?: string[];
}

const initialState: SearchState = {
  query: '',
  isLoading: false,
};

const quickSearchSlice = createSlice({
  name: 'quickSearch',
  initialState,
  reducers: {
    setSearchQuery: (
      state,
      action: PayloadAction<Pick<SearchState, 'query'>>
    ) => {
      state.query = action.payload.query;
    },
    clearSearch: () => initialState,
    clearResult: (state, action) => {
      return {
        ...initialState,
        query: action.payload.query,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchQuickSearchResult.fulfilled, (state, action) => {
      const result = action.payload;

      if (result?.isSuccess) {
        return {
          ...state,
          result: result.data,
          isLoading: false,
        };
      }
      if (!result) {
        return { ...state, isLoading: true };
      }

      return { ...state, isLoading: false };
    });
    builder.addCase(fetchQuickSearchResult.pending, (state) => {
      return {
        ...state,
        result: undefined,
        isLoading: true,
      };
    });
    builder.addCase(fetchSuggestionResult.pending, (state) => {
      return { ...state, isLoading: true };
    });
    builder.addCase(fetchSuggestionResult.fulfilled, (state, action) => {
      const result = action.payload;

      if (result?.isSuccess) {
        return { ...state, suggestions: result.data, isLoading: false };
      }

      return state;
    });
  },
});

export const { setSearchQuery, clearSearch, clearResult } =
  quickSearchSlice.actions;

export const useClearQuickSearch = () => {
  const dispatch = useAppDispatch();
  return () => dispatch(clearSearch());
};

export default quickSearchSlice.reducer;
export const useQuickSearchResults = () =>
  useAppSelector((state) => state.quickSearch);

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from 'app/store';
import { fetchSearchResultThunk } from '../api/search';
import { FullSearchResult } from '../models/searchModels';

export interface SearchState {
  query: string;
  isLoading?: boolean;
  searchResult?: FullSearchResult;
  suggestions?: string[];
  didYouMean?: string[];
}

const initialState: SearchState = {
  query: '',
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchQuery: (
      state,
      action: PayloadAction<Pick<SearchState, 'query'>>
    ) => {
      state.query = action.payload.query;
    },
    clearSearch: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSearchResultThunk.fulfilled, (state, action) => {
      const result = action.payload;

      if (result?.isSuccess) {
        return {
          ...state,
          searchResult: result.data,
          isLoading: false,
        };
      }
      return { ...state, isLoading: false };
    });
    builder.addCase(fetchSearchResultThunk.pending, (state) => {
      return { ...state, isLoading: true };
    });
  },
});

export const { setSearchQuery, clearSearch } = searchSlice.actions;

export const useFetchSearch = () => {
  const dispatch = useAppDispatch();
  return (query: string) => dispatch(fetchSearchResultThunk(query));
};

export default searchSlice.reducer;
export const useSearchResults = () => useAppSelector((state) => state.search);

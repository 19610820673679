import { createSelector, createSlice } from '@reduxjs/toolkit';
import { fetchTranslationStateAsync } from './translations';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';

/**
 * Defines the structure of the translations state, which consists of a mapping
 * from translation keys to their string values.
 */
export interface TranslationsState {
  translations: { [key: string]: string };
}

/**
 * The initial state for the translations slice, potentially including some
 * default or test translations.
 */
const initialState: TranslationsState = {
  translations: {},
};

/**
 * A slice for managing translations within the Redux store. It handles
 * fetching and storing translations.
 */
export const translationsSlice = createSlice({
  name: 'translations',
  initialState,
  reducers: {
    // Reducers could be added here for additional functionalities like adding or removing translations dynamically.
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTranslationStateAsync.fulfilled, (state, action) => {
      const result = action.payload;
      if (result?.isSuccess) {
        // Updates the state with the fetched translations.
        state.translations = result.data.translations;
      }
    });
  },
});

/**
 * A hook for accessing a single translation by its key. It returns the
 * translation string if found; otherwise, it returns the key itself.
 *
 * @param {string} key The key for the desired translation.
 * @returns {string} The translation for the given key or the key itself if not found.
 */
export const useTranslation = (key: string) => {
  const translation = useSelector(
    (state: RootState) => state.translations.translations[key]
  );
  return translation || key;
};

/**
 * A hook for accessing multiple translations at once. It accepts an array of
 * keys and returns an object containing each key paired with its translation.
 * If a translation is not found for a key, the key itself is returned.
 *
 * @param {string[]} keys An array of keys for the desired translations.
 * @returns {Record<string, string>} An object mapping each key to its translation or the key itself if not found. Keys here are strongly mapped to incomming keys.
 */
export const useTranslations = <T extends string>(keys: T[]) => {
  const translations = useSelector((state: RootState) => state.translations);

  // Return a memoized selector for the given keys.
  return selectTranslations(keys)(translations);
};

const selectTranslations = <T extends string>(keys: T[]) =>
  createSelector(
    (translations: TranslationsState) => translations,
    (translations: TranslationsState) => {
      return keys.reduce(
        (acc, key) => {
          acc[key] = translations.translations[key] || key;
          return acc;
        },
        {} as Record<T, string>
      );
    }
  );

export default translationsSlice.reducer;

import {
  AnyAction,
  PayloadAction,
  ThunkDispatch,
  createSlice,
} from '@reduxjs/toolkit';
import { useAppSelector } from 'app/store';
import { ErrorDialogProps } from 'DesignComponents/Organisms/Dialog/ErrorDialog';
import { toggleOpenDialog } from 'shared/slice/dialog/dialogSlice';

/**
 * Push error to the store and open the dialog
 * @param error
 * @param dispatch
 */
export const pushError = (
  error: ErrorDialogProps,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>
) => {
  dispatch(setError(error));
  dispatch(toggleOpenDialog('ErrorDialog'));
};

export type PushError = typeof pushError;

export type SetError = typeof appErrorSlice.actions.setError;

interface AppErrorState {
  open?: boolean;
  message?: string;
  inputErrors?: { [key: string]: string };
  appError?: ErrorDialogProps;
}

const initialState: AppErrorState = {};

const appErrorSlice = createSlice({
  name: 'appError',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<Partial<ErrorDialogProps>>) => {
      state.appError = action.payload as ErrorDialogProps;
      state.open = true;
    },
    resetError: () => {
      return initialState;
    },
    toggleOpen: (state) => {
      state.open = !state.open;
    },
  },
});

export default appErrorSlice.reducer;
export const { setError, resetError, toggleOpen } = appErrorSlice.actions;

export const useError = () => useAppSelector((state) => state.appError);

// Importing necessary libraries and utilities from Redux Toolkit, React-Redux, and other modules
import { createSlice } from '@reduxjs/toolkit';
import VariationModel from 'Models/KexVariation/VariationModel.interface';
import PromotionModel from 'Models/Promotions/PromotionModel.interface';
import { RootState, useAppDispatch, useAppSelector } from 'app/store';
import { useSelector } from 'react-redux';
import LineItemBase from '../order/LineItemBase';
import {
  addDiscountCode,
  fetchCartStateAsync,
  removeCartStateAsync,
  removeDiscountCode,
  sendCart,
  updateBulkCart,
  updateBulkCartProps,
  updateCartQuantityStateAsync,
  updateCartStateAsync,
} from './cart';
import { usePrice } from '../globalSettings/globalSettingsSlice';
import { pushGTMEventEcommerce } from 'shared/analytics/gtmEvents';

export interface Price {
  formattedString: string;
  amount: number;
}

export interface PriceModel {
  // Price without discount
  regularPriceExVat: Price;
  regularPriceIncVat: Price;
  // Price with discount
  priceExVat: Price;
  priceIncVat: Price;
  // Discount amount
  discountExVat: Price | null;
  discountIncVat: Price | null;
  discountPercentage: number;
  description: string;
}

export interface SummaryPriceModel {
  unitPrice: PriceModel | undefined;
  totalPriceForItems: PriceModel | undefined;
  shipping: PriceModel | undefined;
  totalPrice: PriceModel | undefined;
  totalVat: Price;
  currency: string;
}

interface LineItemModel<T = LineItemBase> {
  code: string;
  item: T;
  price: PriceModel;
  quantity: number;
}

export interface LineItemModelEXT<T = LineItemBase>
  extends Omit<LineItemModel<T>, 'price' | 'color'> {
  code: string;
  item: T;
  price: SummaryPriceModel;
  quantity: number;
}

/**
 * Interface defining the structure of the cart state.
 */
export interface CartState {
  isLoading?: boolean;
  lineItems: LineItemModelEXT[];
  name?: string;
  numberOfItems: number;
  promotions?: PromotionModel[];
  price?: SummaryPriceModel;
}

/**
 * Interface describing the details required to update an item in the cart.
 */
export interface CartUpdateItem {
  code: string; // Product code identifier for the item
  quantity: number; // Desired quantity for the item
  currency?: string;
  cartItem?: LineItemModelEXT<VariationModel>;
}

/**
 * Interface describing the details required to delete the cart.
 */
export interface RemoveCart {
  id: string; // Unique identifier for the cart
}

// Setting default values for the cart state
export const initialState: CartState = {
  name: '',
  isLoading: false,
  lineItems: [],
  numberOfItems: 0,
  price: undefined,
};

/**
 * Slice for managing cart-related operations in the Redux store.
 */
export const cartSlice = createSlice({
  name: 'cart', // Unique identifier for this slice
  initialState, // Default state values for this slice
  reducers: {
    toggleCartLoading: (state) => {
      state.isLoading = !state.isLoading;
    },
    updateCartState: (state, action) => {
      state.lineItems = action.payload.data.lineItems;
      state.numberOfItems = action.payload.data.numberOfItems;
      state.promotions = action.payload.data.promotions;
      state.price = action.payload.data.price;
      return state;
    },
    updateCartItem: (state, action) => {
      const { result, getPrice, item } = action.payload;

      if (result?.isSuccess) {
        const cartItem = result.data.lineItems.find(
          (i: LineItemModelEXT<VariationModel>) => i.code === item.code
        );

        if (cartItem) {
          pushGTMEventEcommerce(
            'add_to_cart',
            cartItem.price.currency,
            getPrice(cartItem?.price.totalPriceForItems),
            [cartItem],
            getPrice
          );
        }

        return { ...state, ...result.data, isLoading: false };
      }
      return state;
    },
  }, // Contains direct mutative reducer logic (currently none)
  extraReducers: (builder) => {
    // Responding to fetchCartStateAsync's successful completion
    builder.addCase(fetchCartStateAsync.fulfilled, (state, action) => {
      if (!action.payload) return;
      const result = action.payload;

      if (result?.isSuccess) {
        // eslint-disable-next-line
        return result.data;
      }

      // eslint-disable-next-line
      return { ...state, isLoading: false };
    });
    builder.addCase(fetchCartStateAsync.rejected, (state) => {
      state.isLoading = false;
    });
    // Responding to fetchCartStateAsync's initiation
    builder.addCase(fetchCartStateAsync.pending, (state) => {
      return {
        ...state,
        isLoading: true,
      };
    });
    // Responding to updateCartStateAsync's successful completion
    builder.addCase(updateCartStateAsync.fulfilled, (state, action) => {
      const { result, getPrice } = action.payload;

      if (result?.isSuccess) {
        const code = action.meta.arg.item.code;
        const cartItem = result.data.lineItems.find(
          (item) => item.code === code
        );

        if (cartItem) {
          pushGTMEventEcommerce(
            'add_to_cart',
            cartItem.price.currency,
            getPrice(cartItem?.price.totalPriceForItems),
            [cartItem],
            getPrice
          );
        }

        return { ...state, ...result.data, isLoading: false };
      }
      return state;
    });
    builder.addCase(updateCartStateAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateCartStateAsync.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateCartQuantityStateAsync.fulfilled, (state, action) => {
      const { result, getPrice, oldLineItems } = action.payload;

      if (result?.isSuccess) {
        const code = action.meta.arg.item.code;
        const quantity = action.meta.arg.item.quantity;
        const oldQuantity =
          oldLineItems?.find((item) => item.code === code)?.quantity ?? 0;
        const lineItems = result.data.lineItems;
        const cartItem = lineItems.find((item) => item.code === code);
        const isAdding = oldQuantity < quantity;

        if (cartItem) {
          pushGTMEventEcommerce(
            isAdding ? 'add_to_cart' : 'remove_from_cart',
            cartItem.price.currency,
            getPrice(cartItem.price.totalPriceForItems),
            [cartItem],
            getPrice
          );
        }

        return { ...state, ...result.data, isLoading: false };
      }
      return state;
    });
    builder.addCase(updateCartQuantityStateAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateCartQuantityStateAsync.rejected, (state) => {
      state.isLoading = false;
    });

    // Responding to deleteCartStateAsync's initiation
    builder.addCase(removeCartStateAsync.pending, (state) => {
      state.isLoading = true;
    });
    // Responding to deleteCartStateAsync's successful completion
    builder.addCase(removeCartStateAsync.fulfilled, (state) => {
      state.isLoading = false;
      state.lineItems = [];
      state.numberOfItems = 0;
    });
    builder.addCase(updateBulkCart.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateBulkCart.fulfilled, (state, action) => {
      const { result, getPrice } = action.payload;

      if (result?.isSuccess) {
        if (action.meta.arg.items) {
          const codes = action.meta.arg.items.map((item) => item.code);
          pushGTMEventEcommerce(
            'add_reorder_to_cart',
            result.data.price?.currency ?? '',
            getPrice(result.data.price?.totalPriceForItems),
            result.data.lineItems.filter((lineItem) =>
              codes.includes(lineItem.code)
            ) ?? [],
            getPrice
          );
        }

        return { ...state, ...result.data, isLoading: false };
      }
      return state;
    });
    builder.addCase(updateBulkCart.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addDiscountCode.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addDiscountCode.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addDiscountCode.fulfilled, (state, action) => {
      const { result } = action.payload;
      if (result?.isSuccess) {
        return {
          ...state,
          ...result.data,
          isLoading: false,
        };
      }
      return state;
    });
    builder.addCase(removeDiscountCode.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(removeDiscountCode.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(removeDiscountCode.fulfilled, (state, action) => {
      const { result } = action.payload;
      if (result?.isSuccess) {
        return {
          ...state,
          ...result.data,
          isLoading: false,
        };
      }
      return state;
    });
    builder.addCase(sendCart.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(sendCart.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(sendCart.fulfilled, (state) => {
      state.isLoading = false;
    });
  },
});

/**
 * Action to update the cart based on the provided item details.
 *
 * @param {CartUpdateItem} item - Details of the item to update
 */
export const useUpdateCart = () => {
  const { getPrice } = usePrice();
  const dispatch = useAppDispatch();

  return (item: CartUpdateItem) =>
    dispatch(updateCartStateAsync({ item, getPrice }));
};

/**
 * Action to update the cart based on the provided item details.
 *
 * @param {CartUpdateItem} item - Details of the item to update
 */
export const useUpdateCartQuantity = () => {
  const { getPrice } = usePrice();
  const oldLineItems = useAppSelector(
    (state: RootState) => state.cart.lineItems
  );
  const dispatch = useAppDispatch();

  return (item: CartUpdateItem) =>
    dispatch(updateCartQuantityStateAsync({ item, getPrice, oldLineItems }));
};

/**
 * Action to delete the specified cart.
 *
 * @param {RemoveCart} id - Identifier of the cart to delete
 */
export const useRemoveCart = () => {
  const dispatch = useAppDispatch();
  return () => dispatch(removeCartStateAsync());
};

export const useFetchCartState = () => {
  const dispatch = useAppDispatch();
  return () => dispatch(fetchCartStateAsync());
};

export const useUpdateBulkCart = () => {
  const dispatch = useAppDispatch();
  const { getPrice } = usePrice();
  return (items: updateBulkCartProps[]) =>
    dispatch(updateBulkCart({ items, getPrice }));
};

export const useAddDiscountCode = () => {
  const dispatch = useAppDispatch();
  return (code: string) => dispatch(addDiscountCode({ discountCode: code }));
};

export const useRemoveDiscountCode = () => {
  const dispatch = useAppDispatch();
  return (code: string) => dispatch(removeDiscountCode({ discountCode: code }));
};
export const useSendCart = () => {
  const dispatch = useAppDispatch();
  return (email: string) => dispatch(sendCart({ email: email }));
};

const { toggleCartLoading, updateCartState, updateCartItem } =
  cartSlice.actions;

export { toggleCartLoading, updateCartState, updateCartItem };
/**
 * Hook to retrieve the current cart state from the Redux store.
 */
export const useCart = () => useSelector((state: RootState) => state.cart);

// Exporting the main reducer function for the cart slice
export default cartSlice.reducer;

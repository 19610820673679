import { canUseDOM } from './windowHelper';

export const toParamString = (params: [string, string | number][]) =>
  params.map(([key, value]) => `${key}=${value}`).join('&');

/*
 * Parses url sting into parts
 */
export const parseUrl = (url: string) => {
  const matches = url.match(
    /^(([^:/?#]+:)?(?:\/\/((?:([^/?#:]*):([^/?#:]*)@)?([^/?#:]*)(?::([^/?#:]*))?)))?([^?#]*)(\?[^#]*)?(#.*)?$/
  );
  const m = matches === null ? [] : matches;
  //http://username:password@localhost:257/deploy/?asd=asd#asd
  const r = {
    hash: m[10] || '', // #asd
    host: m[3] || '', // localhost:257
    hostname: m[6] || '', // localhost
    href: m[0] || '', // http://username:password@localhost:257/deploy/?asd=asd#asd
    origin: m[1] || '', // http://username:password@localhost:257
    pathname: m[8] || (m[1] ? '/' : ''), // /deploy/
    port: m[7] || '', // 257
    protocol: m[2] || '', // http:
    search: m[9] || '', // ?asd=asd
    username: m[4] || '', // username
    password: m[5] || '', // password
  };
  if (r.protocol.length === 2) {
    r.protocol = 'file:///' + r.protocol.toUpperCase();
    r.origin = r.protocol + '//' + r.host;
  }
  r.href = r.origin + r.pathname + r.search + r.hash;
  return m && r;
};

/*
 * get value for url parameter
 */
export const getUrlParameter = (name: string, queryString?: string) => {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(
    queryString ? queryString : canUseDOM() ? window.location.search : ''
  );
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const setUrlParameters = (params: [string, string][]): string => {
  if (!canUseDOM()) return '';

  const searchParams = new URLSearchParams('');
  params.forEach(([key, value]) => searchParams.set(key, value));

  const paramsString = searchParams.toString();

  window.history.replaceState(
    null,
    '',
    [window.location.pathname, paramsString].filter((x) => !!x).join('?')
  );

  return paramsString;
};

export const setUrlParameter = (key: string, value: string) => {
  if (!canUseDOM()) return;

  const searchParams = new URLSearchParams(window.location.search);
  if (value === '') {
    searchParams.delete(key);
  } else {
    searchParams.set(key, value);
  }
  const newRelativePathQuery =
    window.location.pathname + '?' + searchParams.toString();

  window.history.replaceState(null, '', newRelativePathQuery);
};

export const getLastUrlSegment = (url: string) => {
  const segments = url.split('/');
  const param = segments.length > 0 ? segments[segments.length - 1] : '';
  if (param === '')
    return segments.length > 0 ? segments[segments.length - 2] : '';
  return param;
};

export type UpdateUrl = ({
  skip,
  take,
  instock,
}: {
  skip?: number;
  take?: number;
  instock?: string;
  sortOrder?: string;
  query?: string;
}) => void;

export const updateUrl: UpdateUrl = ({ skip, take, sortOrder, query }) => {
  // Parse the current URL parameters
  const searchParams = new URLSearchParams(window.location.search);

  // Only update the relevant parameters
  if (query !== undefined && query !== '') {
    searchParams.set('query', query);
  } else {
    searchParams.delete('query');
  }
  if (skip !== undefined) {
    searchParams.set('skip', String(skip));
  }
  if (take !== undefined) {
    searchParams.set('take', String(take));
  }
  if (sortOrder !== undefined) {
    searchParams.set('sortOrder', sortOrder);
  }

  // Update the URL without reloading the page
  const newUrl =
    searchParams && searchParams.toString().length > 0
      ? searchParams.toString()
        ? `${window.location.pathname}?${searchParams.toString()}`
        : window.location.pathname
      : window.location.pathname;

  window.history.replaceState(null, '', newUrl);
};

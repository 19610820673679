import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, useAppDispatch } from 'app/store';
import { useSelector } from 'react-redux';

export type GenericData<T> = Record<string, T>;

export interface GenericPayloadData<T> {
  sliceId: string;
  data: T;
}

export type GenericState<T = object> = Record<string, T>;

export const initialState: GenericState = {};

const genericSlice = createSlice({
  name: 'generic',
  initialState,
  reducers: {
    setGenericDataOnSliceId: <T>(
      state: GenericState<T>,
      action: PayloadAction<GenericPayloadData<T>>
    ) => {
      const { sliceId, data } = action.payload;
      state[sliceId] = data;

      return state;
    },
    updateGenericDataOnSliceId: <T>(
      state: GenericState<T>,
      action: PayloadAction<GenericPayloadData<Partial<T>>>
    ) => {
      const { sliceId, data } = action.payload;
      state[sliceId] = { ...state[sliceId], ...data };

      return state;
    },
  },
});

export const { setGenericDataOnSliceId, updateGenericDataOnSliceId } =
  genericSlice.actions;

export default genericSlice.reducer;

/*
  Alternative to hook below. Use this if you need to get the data from the store in a function that is not a hook or inside an effect.
  First initialize the dispatcher:
    const dispatch = useAppDispatch();

  Then use it:
    dispatch(setGenericDataOnSliceId({ sliceId, data }));
*/
export const useSetGenericData = <T>(sliceId: string, data: T) => {
  const dispatch = useAppDispatch();
  dispatch(setGenericDataOnSliceId({ sliceId, data }));
};

/*
  Alternative to hook below. Use this if you need to get the data from the store in a function that is not a hook or inside an effect.
  First initialize the dispatcher:
    const dispatch = useAppDispatch();

  Then use it:
    dispatch(updateGenericDataOnSliceId({ sliceId, data }));
*/
export const useUpdateGenericData = <T>(sliceId: string, data: Partial<T>) => {
  const dispatch = useAppDispatch();
  dispatch(updateGenericDataOnSliceId({ sliceId, data }));
};

export const useSelectGenericData = <T>(sliceId: string) =>
  useSelector((state: RootState) => state.genericData[sliceId] as T);

import {
  ExceptionResponse,
  FailureResponse,
  http,
  SuccessResponse,
} from 'app/fetch/fetcher';

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAPIBasedOnSystem,
  getApiFromSession,
} from 'DesignSystem/StyledComponets/Settings/SystemSwitch';
import { CurrentPage, GenericPageState } from './currentPageSlice';
const fetchPageUrl = `api/content/page`;

export const fetchPage = (
  url: string,
  onError: (error: FailureResponse | ExceptionResponse) => void
) => {
  const target = `${import.meta.env.VITE_USE_MIDDLELAYER === 'true' ? '' : getAPIBasedOnSystem()}${location.pathname}${location.search}`;
  return http
    .handleHttpResponse<GenericPageState>(
      http.get(url, {
        headers: { 'Context-Url': `${getApiFromSession()}${target}` },
      }),
      undefined,
      (err) => err && onError(err)
    )
    .then((response) => {
      if (!response) {
        return {
          status: 404,
          data: {
            pageType: 'NotFoundPage',
          },
        };
      }
      return response;
    });
};

export const fetchCurrentPageAsync = createAsyncThunk(
  'currentPage/fetchCurrentPage',
  async () => {
    const target = `${getAPIBasedOnSystem()}/${fetchPageUrl}`;
    return fetchPage(target, (error: FailureResponse | ExceptionResponse) => {
      return {
        status: error.status,
        data: {
          pageType: 'NotFoundPage',
        },
      } as SuccessResponse<CurrentPage>;
    });
  }
);

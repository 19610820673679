import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from 'app/fetch/fetcher';
import { abortControllers } from 'app/store';
import { setError } from '../../../app/shared/appError/appErrorSlice';
import { QuickSearchResult } from '../../search/models/searchModels';
import { fetchResource } from '../../../app/shared/utils/sliceUtils';
import { getAPIBasedOnSystem } from 'DesignSystem/StyledComponets/Settings/SystemSwitch';

const searchApiUrl = 'api/search';

export const fetchQuickSearchResult = createAsyncThunk(
  'search/quick-search',
  async (query: string, { dispatch }) => {
    const url = `${getAPIBasedOnSystem()}/${searchApiUrl}/sssquick-search?query=${query}`;
    const abortController = abortControllers.get('search/quick-search');
    return http.handleHttpResponse<QuickSearchResult>(
      http.get<QuickSearchResult>(url, {
        signal: abortController.signal,
      }),
      dispatch,
      (err) =>
        dispatch(
          setError({
            message: err
              ? err.data.message
              : 'Failed to fetch category products',
          })
        )
    );
  }
);

export const fetchSuggestionResult = createAsyncThunk(
  'search/suggestions',
  async (query: string, { dispatch }) => {
    const url = `${getAPIBasedOnSystem()}/${searchApiUrl}/suggestions?query=${query}`;
    const abortController = abortControllers.get('search/suggestions');
    return fetchResource<string[]>(
      url,
      dispatch,
      (err) =>
        dispatch(
          setError({
            message: err ? err.message : 'Failed to fetch category products',
          })
        ),
      {
        signal: abortController.signal,
      }
    );
  }
);

/*
 * Used to check if we are in server context or not.
 */

import { LineItemModelEXT } from 'app/shared/cart/cartSlice';
import LineItemBase from 'Commerce/shared/lineItems/lineItemBase';
import { useEffect, useState } from 'react';

export const canUseDOM = () => {
  return !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  );
};

type DimensionType = {
  innerWidth: number;
  innerHeight: number;
};

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState<DimensionType>({
    innerWidth: 0,
    innerHeight: 0,
  });

  /* eslint consistent-return: off */
  useEffect(() => {
    canUseDOM() &&
      setWindowDimensions({
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      });
    function handleResize() {
      if (canUseDOM())
        setWindowDimensions({
          innerWidth: window.innerWidth,
          innerHeight: window.innerHeight,
        });
    }
    if (canUseDOM()) window.addEventListener('resize', handleResize);

    if (canUseDOM())
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export function downloadCSV(lineItems: LineItemModelEXT<LineItemBase>[]) {
  const data: string[] = [];
  lineItems.forEach((item) =>
    data.push([item.code, item.item.name, item.quantity.toString()].join(','))
  );
  const csvContent = 'data:text/csv;charset=utf-8,' + data.join('\r\n');
  const encodedUri = encodeURI(csvContent);
  window.open(encodedUri);
}
export const outsideOfElement = (
  target: Element,
  e: React.MouseEvent<Element>
) => {
  const rect = target.getBoundingClientRect();
  switch (true) {
    case (rect.right <= e.clientX && e.clientX > rect.left) ||
      (rect.left >= e.clientX && e.clientX <= rect.right):
      return true;
    case (rect.bottom <= e.clientY && e.clientY > rect.top) ||
      (rect.top >= e.clientY && e.clientY <= rect.bottom):
      return true;
    default:
      return false;
  }
};

// Use this to circumvent lint warning
// eslint-disable-next-line
export const consoleLog = console.log;

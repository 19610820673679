import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { useSelector } from 'react-redux';
import { Address } from '../customer/customerSlice';
import ShippingMethodModel from 'Models/Shipping/ShippingMethodModel.interface';
import PaymentMethodModel from 'Models/Payment/PaymentMethodModel.interface';

export interface CheckoutStepBase {
  completed?: boolean;
  active?: boolean;
}

export type ContactDetailsStep = {
  completed?: boolean;
  active?: boolean;
  selectedAddress?: Address;
};

export type DeliveryStep = {
  completed?: boolean;
  active?: boolean;
  selectedShippingMethod?: ShippingMethodModel;
  requestedDeliveryDate?: string;
};

export type PaymentStep = {
  completed?: boolean;
  active?: boolean;
  selectedPaymentMethod?: PaymentMethodModel;
};

export interface CheckoutStepsState {
  contactStep?: ContactDetailsStep;
  deliveryStep?: DeliveryStep;
  paymentStep?: PaymentStep;
}

type CheckoutStateType = {
  checkoutStepsState: CheckoutStepsState;
};

// Define the initial state using the CheckoutStateType
const initialState: CheckoutStateType = {
  checkoutStepsState: {
    contactStep: {
      active: true,
      completed: false,
    },
    deliveryStep: {
      active: false,
      completed: false,
      requestedDeliveryDate: '',
    },
    paymentStep: {
      active: false,
      completed: false,
    },
  },
};

// Create the slice
const checkoutStateSlice: Slice<CheckoutStateType> = createSlice({
  name: 'checkoutSlice',
  initialState: initialState,
  reducers: {
    updateContactDetails: (state, action: PayloadAction<Address>) => {
      return {
        ...state,
        checkoutStepsState: {
          ...state.checkoutStepsState,
          contactStep: {
            active: false,
            completed: true,
            selectedAddress: { ...action.payload },
          },
          deliveryStep: {
            ...state.checkoutStepsState.deliveryStep,
            active: true,
          },
          paymentStep: {
            ...state.checkoutStepsState.paymentStep,
            active: false,
          },
        },
      };
    },
    updateDeliveryDetails: (
      state,
      action: PayloadAction<ShippingMethodModel>
    ) => {
      return {
        ...state,
        checkoutStepsState: {
          ...state.checkoutStepsState,
          deliveryStep: {
            ...state.checkoutStepsState.deliveryStep,
            active: false,
            completed: true,
            selectedShippingMethod: action.payload,
          },
          paymentStep: {
            ...state.checkoutStepsState.paymentStep,
            active: true,
          },
        },
      };
    },
    updateDeliveryDate: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        checkoutStepsState: {
          ...state.checkoutStepsState,
          deliveryStep: {
            ...state.checkoutStepsState.deliveryStep,
            requestedDeliveryDate: action.payload,
          },
        },
      };
    },
    updatePaymentMethod: (state, action: PayloadAction<PaymentMethodModel>) => {
      return {
        ...state,
        checkoutStepsState: {
          ...state.checkoutStepsState,
          paymentStep: {
            active: true,
            selectedPaymentMethod: action.payload,
          },
        },
      };
    },
    close: (state) => {
      return {
        ...state,
        checkoutStepsState: {
          ...state.checkoutStepsState,
          contactStep: {
            ...state.checkoutStepsState.contactStep,
            active: false,
          },
          deliveryStep: {
            ...state.checkoutStepsState.deliveryStep,
            active: false,
          },
        },
      };
    },
    setActive: (state, action: PayloadAction<string>) => {
      switch (action.payload) {
        case 'contact':
          return {
            ...state,
            checkoutStepsState: {
              ...state.checkoutStepsState,
              contactStep: {
                ...state.checkoutStepsState.contactStep,
                active: true,
              },
              deliveryStep: {
                ...state.checkoutStepsState.deliveryStep,
                active: false,
              },
              paymentStep: {
                ...state.checkoutStepsState.paymentStep,
                active: false,
              },
            },
          };
        case 'delivery':
          return {
            ...state,
            checkoutStepsState: {
              ...state.checkoutStepsState,
              contactStep: {
                ...state.checkoutStepsState.contactStep,
                active: false,
              },
              deliveryStep: {
                ...state.checkoutStepsState.deliveryStep,
                active: true,
              },
              paymentStep: {
                ...state.checkoutStepsState.paymentStep,
                active: false,
              },
            },
          };
        case 'payment':
          return {
            ...state,
            checkoutStepsState: {
              ...state.checkoutStepsState,
              contactStep: {
                ...state.checkoutStepsState.contactStep,
                active: false,
              },
              deliveryStep: {
                ...state.checkoutStepsState.deliveryStep,
                active: false,
              },
              paymentStep: {
                ...state.checkoutStepsState.paymentStep,
                active: true,
              },
            },
          };
        default:
          return { ...state };
      }
    },
  },
});

// Export the action creator for toggleModal
export const {
  updateContactDetails,
  updateDeliveryDetails,
  updateDeliveryDate,
  updatePaymentMethod,
  setActive,
  setQuery,
  close,
} = checkoutStateSlice.actions;

export const useCheckoutStepsState = () =>
  useSelector(
    (state: RootState) =>
      state &&
      state.checkoutStepsData &&
      state.checkoutStepsData.checkoutStepsState
  );

// Export the custom hooks using the useSelector and useDispatch hooks from 'react-redux'
// ... (your existing useGlobalSettings and useGlobalSettingsDispatch hooks code here)

// Export the reducer
export default checkoutStateSlice.reducer;

import { canUseDOM } from 'app/helpers/windowHelper';
import { FieldError, FieldErrors } from 'react-hook-form';
import { Price, PriceModel } from '../cart/cartSlice';
import { plainState } from 'app/store';

/**
 * Returns a new object that only contains records with specified keys
 */
export const pick = <T extends object, K extends keyof T>(
  source: T,
  ...keys: K[]
): Pick<T, K> => {
  return keys.reduce<Partial<T>>((acc, key) => {
    acc[key] = source[key];
    return acc;
  }, {}) as Pick<T, K>;
};

/**
 * Returns a new object that does not contain records with specified keys
 */
export const omit = <T extends object, K extends keyof T>(
  source: T,
  ...keys: K[]
): Omit<T, K> => {
  return ObjectEntries(source).reduce<Partial<T>>((acc, [key, val]) => {
    if (!keys.includes(key as K)) acc[key] = val;
    return acc;
  }, {}) as Omit<T, K>;
};

/**
 * Returns a new object (shallow copy) with specified replacements
 */
export const modify = <T extends object>(
  source: T,
  replacement: Partial<T>
) => ({
  ...source,
  ...replacement,
});

/**
 * A type safe alias to Object.keys
 */
export const ObjectKeys = <T extends object>(obj: T) =>
  Object.keys(obj) as (keyof T)[];

/**
 * A type safe alias to Object.entries
 */
export const ObjectEntries = <T extends object>(obj: T) =>
  Object.entries(obj) as [keyof T, T[keyof T]][];

/**
 * Type checks for runtime with type coercion in typescript
 */
export const isDefined = <T>(x: T | undefined | null): x is T => x != null;
export const isString = (x: any): x is string => typeof x === 'string';
export const isNumber = (x: any): x is number =>
  typeof x === 'number' && !isNaN(x);
export const isBoolean = (x: any): x is boolean => typeof x === 'boolean';
export const isSymbol = (x: any): x is symbol => typeof x === 'symbol';
export const isDate = (x: any): x is Date =>
  x instanceof Date && isNumber(x.getTime());
export const isArray = <T>(x: any): x is Array<T> => Array.isArray(x);

export const round = (num: number, decimals: number) =>
  Number(num.toFixed(decimals));

/**
 * Returns a string on the form YYYY-MM-DD
 * @param date Date or a string that can be parsed to a Date
 * @param locale string
 */
export const getIsoDate = (date: Date | string, locale = 'sv-SE') => {
  if (isString(date)) {
    try {
      const parsedDate = Date.parse(date);
      return Intl.DateTimeFormat(locale).format(parsedDate);
    } catch {
      throw new Error(`Could not parse '${date}' to a date`);
    }
  }

  return Intl.DateTimeFormat(locale).format(date);
};

/**
 * Returns a date string on a form depending on locale
 * @param date Date or a string that can be parsed to a Date. If string, it must be an ISO date/datetime string
 * @param locale string (sv, no or en)
 */
export const getDateStr = (date: Date | string, locale: string) => {
  if (isString(date)) {
    try {
      const parsedDate = new Date(date);
      return getLocalizedDateStr(parsedDate, locale);
    } catch {
      throw new Error(`Could not parse '${date}' to a date string`);
    }
  }

  return getLocalizedDateStr(date, locale);
};

/**
 * Returns a date string on a form depending on locale
 * @param date Date
 * @param locale string (sv, no or en)
 */
export const getLocalizedDateStr = (date: Date, locale: string) => {
  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(date);
};

export const getKeyValue = <K extends PropertyKey, V>(obj: Record<K, V>) => {
  const [firstKey] = ObjectKeys(obj);

  return { key: firstKey, value: obj[firstKey] };
};

/**
 * Refines in a zod schema are not accessible after validation.
 * Use force to get a field error, if it exists.
 * @param errors FieldErrors comming from react-hook-form useForm
 * @param path: String name of defined path in a zod refine
 */
export const extractZodPathError = (
  errors: FieldErrors,
  path: string
): string | undefined => {
  const error = errors[path] as FieldError | undefined;
  return error?.message;
};

/**
 * Format a string with supplied substitutes
 * @param str string: String containing some markers (on the form {marker})
 * @param substitutes Array<string | number> - Substitutes for provided markers
 *
 * Example: formatString('Hello {name}, are you {mood}?', 'David', 'sleepy');
 *
 * Result: 'Hello David, are you sleepy?'
 */
export const formatString = (
  str: string,
  ...substitutes: (string | number)[]
): string => {
  if (substitutes === undefined || substitutes.length === 0) return str;

  const regex = new RegExp(/{\w+}/);
  const parts = str.split(regex);

  return parts.reduce<string>((acc, part, idx) => {
    if (substitutes[idx]) acc += part + substitutes[idx];
    else acc += part;

    return acc;
  }, '');
};

export const getRandomString = (length: number, charsToUse?: string) => {
  const chars =
    charsToUse ?? 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let randomString = '';

  if (canUseDOM()) {
    const array = new Uint32Array(length);
    window.crypto.getRandomValues(array);

    for (let i = 0; i < length; i++) {
      randomString += chars[array[i] % chars.length];
    }
  } else {
    for (let i = 0; i < length; i++) {
      const rnum = Math.floor(Math.random() * chars.length);
      randomString += chars[rnum];
    }
  }

  return randomString;
};

const showPriceWithVAT = () => {
  return plainState.get('commerce') === 'OptimizelyCms' ? true : false;
};

// Price with discount
export const getPriceModel = (
  priceModel: PriceModel | undefined | null
): Price | undefined => {
  if (priceModel == undefined) return undefined;

  return showPriceWithVAT() ? priceModel.priceIncVat : priceModel.priceExVat;
};

// Price without discount
export const getRegularPriceModel = (
  priceModel: PriceModel | undefined | null
): Price | undefined => {
  if (priceModel == undefined) return undefined;

  return showPriceWithVAT()
    ? priceModel.regularPriceIncVat
    : priceModel.regularPriceExVat;
};

// Discount amount
export const getDiscountModel = (
  priceModel: PriceModel | undefined | null
): Price | undefined => {
  if (priceModel == undefined) return undefined;

  return (
    (showPriceWithVAT()
      ? priceModel.discountIncVat
      : priceModel.discountExVat) ?? undefined
  );
};

export const emptyPrice: Price = {
  amount: 0,
  formattedString: '',
};

// Price with discount
export const getPrice = (priceModel: PriceModel | undefined | null) => {
  return getPriceModel(priceModel)?.formattedString ?? '';
};

// Price without discount
export const getRegularPrice = (
  priceModel: PriceModel | undefined | null,
  supressIfSame = true
) => {
  if (supressIfSame && priceModel?.discountPercentage === 0) return '';

  return getRegularPriceModel(priceModel)?.formattedString ?? '';
};

// Discount amount
export const getDiscount = (priceModel: PriceModel | undefined) => {
  return getDiscountModel(priceModel)?.formattedString ?? '';
};

// Only allow one fetch request in flight (based on id) and abort previous if it exists
export default class AbortControllers {
  private abortControllers: Record<string, AbortController> = {};

  get(id: string) {
    // Abort previous controller if it exists
    this.abortControllers[id]?.abort();

    // Return a new controller
    return this.set(id);
  }

  private set(id: string) {
    const newController = new AbortController();
    this.abortControllers[id] = newController;

    return newController;
  }
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAPIBasedOnSystem } from 'DesignSystem/StyledComponets/Settings/SystemSwitch';
import FooterModel from 'Models/Footer/FooterModel.interface';
import { http } from 'app/fetch/fetcher';

export const fetchFooterDataAsync = () => {
  return fetchFooterAsync(`api/content/footer`);
};

export const fetchFooterAsync = createAsyncThunk(
  'api/content/footer',
  async (url: string) => {
    return http.handleHttpResponse<FooterModel>(
      http.get(getAPIBasedOnSystem() + '/' + url)
    );
  }
);

import { createSlice } from '@reduxjs/toolkit';
import {
  fetchFooterAsync,
  fetchFooterDataAsync,
} from 'DesignComponents/Organisms/Footer/FetchFooter';
import FeatureTypeEnum from 'Models/Footer/FeatureTypeEnum.interface';
import FooterModel from 'Models/Footer/FooterModel.interface';
import { useAppDispatch, useAppSelector } from 'app/store';

const initialState: FooterModel = {
  copyright: '',
  facebookLink: { href: '', title: '', id: '', text: '', target: '' },
  instagramLink: { href: '', title: '', id: '', text: '', target: '' },
  linkedInLink: { href: '', title: '', id: '', text: '', target: '' },
  featuredArea: {
    feature: FeatureTypeEnum.BecomeMember,
    heading: '',
    text: '',
  },
};

const footerSlice = createSlice({
  name: 'footer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFooterAsync.fulfilled, (state, action) => {
      const result = action.payload;

      if (result?.isSuccess) {
        return result.data;
      }
      return state;
    });
    builder.addCase(fetchFooterAsync.pending, (state) => {
      return { ...state, isLoading: true };
    });
  },
});

export const useFetchFooter = () => {
  const dispatch = useAppDispatch();
  return () => dispatch(fetchFooterDataAsync());
};

export const useFooterData = <T extends FooterModel>() =>
  useAppSelector((state) => state.footerData) as T;

export default footerSlice.reducer;

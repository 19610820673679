import { createSlice } from '@reduxjs/toolkit';
import { useAppDispatch } from 'app/store';
import { BulkCartUpdate } from '../../../Commerce/Pages/QuickOrder/QuickOrder';
import { CartState } from '../cart/cartSlice';

export interface BulkUpdateProps {
  added: number;
  incorrectFormat: number;
  notFound: number;
  notAdded: number;
  notAddedLineItems: string[];
  addedLineItems: string[];
  notFoundLineItems: string[];
  incorrectFormatLineItems: string[];
  cart: CartState;
}

export const initialState: BulkUpdateProps = {
  added: 0,
  incorrectFormat: 0,
  notFound: 0,
  notAdded: 0,
  notAddedLineItems: [],
  addedLineItems: [],
  notFoundLineItems: [],
  incorrectFormatLineItems: [],
  cart: {
    lineItems: [],
    numberOfItems: 0,
  },
};

export enum QuickOrderSearchStatus {
  ANALYSED = 'analysed',
  ANALYSING = 'analysing',
  DEFAULT = 'default',
}

export interface AccordianResult {
  code: string[];
  quantity: number;
  heading: string;
  id: string;
}

export interface AccordianItemsResult {
  incorrectFormat: AccordianResult;
  notFound: AccordianResult;
  notAdded: AccordianResult;
}

/**
 * Slice for managing quick order bulk update operations in the Redux store.
 */
export const bulkUpdateCartSlice = createSlice({
  name: 'bulkUpdate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(BulkCartUpdate.fulfilled, (state, action) => {
      if (!action.payload) return;
      const result = action.payload;
      if (result.isSuccess) {
        state = result.data;
      }
    });
    builder.addCase(BulkCartUpdate.rejected, (state) => {
      return state;
    });
    builder.addCase(BulkCartUpdate.pending, (state) => {
      return state;
    });
  },
});

export const useBulkUpdate = () => {
  const dispatch = useAppDispatch();
  return (query: string[]) => dispatch(BulkCartUpdate(query));
};

export default bulkUpdateCartSlice.reducer;

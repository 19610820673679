import ProductCardSkeleton from 'Commerce/Molecules/Card/ProductCard/ProductCardSkeleton';
import ContentContainer from 'DesignComponents/Molecules/ContentContainer/ContentContainer';
import SkeletonLoader from 'DesignComponents/Organisms/SkeletonLoader/SkeletonLoader';
import styled from 'styled-components';

const ProductPageSkeleton = () => {
  const imageCounter = 5;
  return (
    <ContentContainer>
      <SkeletonLoader>
        <ProductWrapper>
          <ProductImagesContainer>
            {Array(imageCounter)
              .fill('empty')
              .map((val, index) => (
                <ProductImage
                  key={index}
                  className="skeletonLoader"
                ></ProductImage>
              ))}
          </ProductImagesContainer>
          <ProductDetailsConatiner>
            <MainInfo className="skeletonLoader"></MainInfo>
            <ProductTitle className="skeletonLoader"></ProductTitle>
            <VariantTitle className="skeletonLoader"></VariantTitle>
            <Wrapper>
              <VariantThumbnail className="skeletonLoader"></VariantThumbnail>
              <VariantThumbnail className="skeletonLoader"></VariantThumbnail>
            </Wrapper>
            <Price className="skeletonLoader"></Price>
            <Wrapper>
              <AddToBag className="skeletonLoader"></AddToBag>
              <AddToBagButton className="skeletonLoader"></AddToBagButton>
            </Wrapper>
            <GlobalDiscount className="skeletonLoader"></GlobalDiscount>
            <UspListFirst className="skeletonLoader"></UspListFirst>
            <UspListSecond className="skeletonLoader"></UspListSecond>
            <Details className="skeletonLoader"> </Details>
            <Details className="skeletonLoader"> </Details>
          </ProductDetailsConatiner>
        </ProductWrapper>
        <SimilarProductsTitle className="skeletonLoader" />
      </SkeletonLoader>
      <ProductCardWrapper>
        {Array(imageCounter - 1)
          .fill('empty')
          .map((val, index) => (
            <ProductCardSkeleton key={index} />
          ))}
      </ProductCardWrapper>
    </ContentContainer>
  );
};

export default ProductPageSkeleton;

const ProductImage = styled.div`
  width: 49%;
  height: 14.5625rem;
  &:first-child {
    width: 99%;
    height: 27.125rem;
  }
`;

const ProductWrapper = styled.div`
  display: flex;
  width: -webkit-fill-available;
  gap: ${({ theme }) => theme.space.s200};
  margin-bottom: ${({ theme }) => theme.space.s200};
`;

const ProductImagesContainer = styled.div`
  width: 49%;
  display: flex;
  gap: ${({ theme }) => theme.space.s50};
  flex-wrap: wrap;
`;

const ProductDetailsConatiner = styled.div`
  display: flex;
  width: 48%;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.space.s50};
`;

const MainInfo = styled.div`
  width: 10.4375rem;
  height: 1rem;
`;

const ProductTitle = styled.div`
  height: 2.5rem;
  width: 17.4375rem;
  margin-bottom: ${({ theme }) => theme.space.s100};
`;
const VariantTitle = styled.div`
  height: 1.5rem;
  width: 33rem;
  margin-bottom: ${({ theme }) => theme.space.s150};
`;
const VariantThumbnail = styled.div`
  width: 6.25rem;
  height: 6.25rem;
  margin-bottom: ${({ theme }) => theme.space.s200};
`;
const Price = styled.div`
  width: 7.3125rem;
  height: 2.5rem;
  margin-bottom: ${({ theme }) => theme.space.s100};
`;
const AddToBag = styled.div`
  width: 29.5rem;
  height: 3rem;
  margin-bottom: ${({ theme }) => theme.space.s75};
`;
const AddToBagButton = styled.div`
  width: 3rem;
  height: 3rem;
`;
const GlobalDiscount = styled.div`
  width: 10.4375rem;
  height: 1rem;
  margin-bottom: ${({ theme }) => theme.space.s200};
`;
const UspListFirst = styled.div`
  width: 10.4375rem;
  height: 1.5rem;
  margin-bottom: ${({ theme }) => theme.space.s75};
`;

const UspListSecond = styled.div`
  width: 15.0625rem;
  height: 1.5rem;
  margin-bottom: ${({ theme }) => theme.space.s300};
`;

const Details = styled.div`
  width: 33rem;
  height: 5rem;
`;

const Wrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space.s50};
`;
const ProductCardWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space.s50};
`;

const SimilarProductsTitle = styled.div`
  display: flex;
  width: 16.75rem;
  height: 2.5rem;
  margin-bottom: ${({ theme }) => theme.space.s150};
  margin-top: ${({ theme }) => theme.space.s500};
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from 'app/fetch/fetcher';
import { abortControllers } from 'app/store';
import { setError } from '../../../app/shared/appError/appErrorSlice';
import { fetchResource } from '../../../app/shared/utils/sliceUtils';
import { FullSearchResult, QuickSearchResult } from '../models/searchModels';
import { getAPIBasedOnSystem } from 'DesignSystem/StyledComponets/Settings/SystemSwitch';

const searchApiUrl = 'api/search';
export let quickSearchAbortController = new AbortController();
export const fetchQuickSearchResult = createAsyncThunk(
  'search/quick-search',
  async (query: string, { dispatch }) => {
    const url = `${getAPIBasedOnSystem()}/${searchApiUrl}/quick-search?query=${query}`;
    quickSearchAbortController = new AbortController();
    return http.handleHttpResponse<QuickSearchResult>(
      http.get<QuickSearchResult>(url, {
        signal: quickSearchAbortController.signal,
      }),
      dispatch,
      (err) => err?.data && dispatch(setError(err.data))
    );
  }
);

export const fetchSearchResultThunk = createAsyncThunk(
  'search/search',
  async (query: string, { dispatch }) => {
    const url = `${getAPIBasedOnSystem()}/${searchApiUrl}?${query}`;
    return fetchResource<FullSearchResult>(
      url,
      dispatch,
      (err) => err && dispatch(setError(err))
    );
  }
);

export const fetchSuggestionResult = createAsyncThunk(
  'search/suggestions',
  async (query: string, { dispatch }) => {
    const url = `${getAPIBasedOnSystem()}/${searchApiUrl}/suggestions?query=${query}`;
    const abortController = abortControllers.get('search/suggestions');
    return fetchResource<string[]>(
      url,
      dispatch,
      (err) => err && dispatch(setError(err)),
      {
        signal: abortController.signal,
      }
    );
  }
);

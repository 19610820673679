import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from 'app/store';

export interface DialogState {
  type?: string;
  open: boolean;
  close?: boolean;
}

const initialState: DialogState = {
  open: false,
  close: false,
};

const dialogState = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    toggleOpenDialog: (state, action: PayloadAction<string>) => {
      state.open = !state.open;
      state.type = action.payload;
    },
    toggleCloseDialog: (state) => {
      state.close = !state.close;
      state.type = '';
    },
  },
});

export default dialogState.reducer;
export const { toggleOpenDialog, toggleCloseDialog } = dialogState.actions;

export const useDialog = () => useAppSelector((state) => state.dialog);

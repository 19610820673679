import {
  FailureResponse,
  ExceptionResponse,
  SuccessResponse,
} from 'app/fetch/fetcher';
import { HttpStatusCode } from 'app/shared/types/HttpTypes';

export function checkError<ResponseType>(
  response: Response,
  data: ResponseType
) {
  switch (response.status) {
    case HttpStatusCode.NoContent:
    case HttpStatusCode.Ok: // Handle OK response
      return successResponse(data as ResponseType, response.status);
    case HttpStatusCode.Unauthorized: // Handle Unauthorized response
    case HttpStatusCode.Forbidden: // Handle Forbidden response
    case HttpStatusCode.MethodNotAllowed: // Handle Method Not Allowed response
      return failureResponse(
        {
          message: 'Access forbidden. No further details provided.',
        },
        response.status
      );
    case HttpStatusCode.NotFound: // Handle Not Found response
      return failureResponse(
        { message: 'Endpoint not found.' },
        response.status
      );
    case HttpStatusCode.InternalServerError: // Handle Internal Server Error response
      return exceptionResponse(
        response.status,
        `An unexpected status code was received: ${response.status}`
      );
    default: // Handle other cases including server errors
      return exceptionResponse(
        response.status,
        `An unexpected status code was received: ${response.status}`
      );
  }
}

export const failureResponse = (
  data: FailureResponse['data'],
  status: number
): FailureResponse => {
  return {
    isFailure: true,
    status,
    data,
  };
};

export const exceptionResponse = (
  status: number,
  message: string
): ExceptionResponse => {
  return {
    isException: true,
    status,
    message,
    data: {
      message,
    },
  };
};

export const successResponse = <T>(
  data: T,
  status: number
): SuccessResponse<T> => {
  return {
    isSuccess: true,
    status,
    data,
  };
};

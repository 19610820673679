export const truncateString = (value: string, number: number) => {
  if (value === null || value === undefined) return value;
  if (value.length <= number) {
    return value;
  }
  return value.slice(0, number) + '...';
};

export const firstLetterLower = (text: string) => {
  const firstLetterCap = text.charAt(0).toLowerCase();

  const remainingLetters = text.slice(1);

  return firstLetterCap + remainingLetters;
};

export const firstLetterCapital = (text: string) => {
  if (!text) return text;
  const firstLetterCap = text.charAt(0).toUpperCase();

  const remainingLetters = text.slice(1);

  return firstLetterCap + remainingLetters;
};

export function highlightMatch(searchFor: string, searchIn: string): string {
  const matchIndex = searchIn.toLowerCase().indexOf(searchFor.toLowerCase());

  if (matchIndex === -1) {
    return searchIn; // Return the original string if no match
  }

  const matchLength = searchFor.length;
  const beforeMatch = searchIn.substring(0, matchIndex);
  const match = searchIn.substring(matchIndex, matchIndex + matchLength);
  const afterMatch = searchIn.substring(matchIndex + matchLength);

  return `${beforeMatch}<b>${match}</b>${afterMatch}`;
}

export function replacePlaceholder(text: string, value?: string[]): string {
  let newValue: string = text;
  if (value)
    value.forEach((val, index) => {
      newValue = newValue.replace(`{${index}}`, val);
    });
  return newValue;
}

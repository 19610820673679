import { createSlice } from '@reduxjs/toolkit';
import ProductCardModel from 'Commerce/Molecules/Card/ProductCard/ProductCardModel.interface';
import { ProductResultModel } from 'shared/search/models/searchModels';
import { useAppDispatch, useAppSelector } from 'app/store';
import { fetchCategorySearchResult } from './category';

export interface CategorySearchState extends ProductResultModel {
  isLoading: boolean;
  loadingMore: boolean;
}

const initialState: CategorySearchState = {} as CategorySearchState;

const currentPageSlice = createSlice({
  name: 'categorySearch',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategorySearchResult.fulfilled, (state, action) => {
        const response = action.payload;
        const prevRes = action.meta.arg.result;
        let newRes;
        if (response) {
          if (prevRes && response) {
            newRes = {
              ...response,
              data: {
                ...response.data,
                result: response
                  ? [...prevRes, ...response.data.result]
                  : [...prevRes],
              },
            };
          }
        }

        if (response?.isSuccess) {
          if (newRes) {
            return {
              ...state,
              isLoading: false,
              ...newRes.data,
            };
          }
          return {
            ...state,
            isLoading: false,
            ...response.data,
          };
        }
        return state;
      })
      .addCase(fetchCategorySearchResult.pending, (state) => {
        return { ...state, isLoading: true };
      });
  },
});

export default currentPageSlice.reducer;

export const useCategorySearchResult = () =>
  useAppSelector((state) => state.categorySearch);

export const useFetchCategoryResult = () => {
  const dispatch = useAppDispatch();
  return (
    categoryId: string,
    skip = 0,
    take = 12,
    params?: [string, string][],
    result?: ProductCardModel[]
  ) =>
    dispatch(
      fetchCategorySearchResult({
        id: categoryId,
        params: params
          ? [['skip', skip], ['take', take], ...params]
          : [
              ['skip', skip],
              ['take', take],
            ],
        result: result,
      })
    );
};

import ColorTokensType from '../Settings/colorTypes';
import { Theme } from '../types';
import darkColors from './dark.colors';
import lightColors from './light.colors';
import tokens from 'DesignSystem/StyledComponets/Settings/tokens';

const themes = {
  light: {
    id: Theme.light,
    colors: { ...lightColors, transparent: 'transparent' },
    ...tokens,
  },
  dark: {
    id: Theme.dark,
    colors: { ...darkColors, transparent: 'transparent' },
    ...tokens,
  },
} as const;

type Tokens = typeof tokens;

export interface Themes extends Tokens {
  colors: ColorTokensType & { transparent: 'transparent' };
}

export default themes;

import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { ExternalRequestInit, http } from 'app/fetch/fetcher';
import { ErrorDialogProps } from 'DesignComponents/Organisms/Dialog/ErrorDialog';

export const fetchResource = <T>(
  url: string,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  setError?: (result: ErrorDialogProps) => void,
  requestInit?: ExternalRequestInit
) => {
  return http.handleHttpResponse<T>(
    http.get<T>(url, requestInit),
    dispatch,
    (err) => err?.data && setError && setError(err.data)
  );
};

export const deleteResource = <T>(
  url: string,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  setError?: (result: ErrorDialogProps) => void,
  requestInit?: ExternalRequestInit
) => {
  return http.handleHttpResponse<T>(
    http.delete<T>(url, requestInit),
    dispatch,
    (err) => err?.data && setError && setError(err.data)
  );
};

export const postResource = <T>(
  url: string,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  body: object,
  setError?: (result: ErrorDialogProps) => void,
  requestInit?: ExternalRequestInit
) => {
  return http.handleHttpResponse<T>(
    http.post<T>(url, { ...requestInit, body: body }),
    dispatch,
    (err) => err?.data && setError && setError(err.data)
  );
};

export const putResource = <T>(
  url: string,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  body: object,
  setError?: (result: ErrorDialogProps) => void,
  requestInit?: ExternalRequestInit
) => {
  return http.handleHttpResponse<T>(
    http.put<T>(url, { ...requestInit, body: body }),
    dispatch,
    (err) => err?.data && setError && setError(err.data)
  );
};

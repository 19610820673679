// ----------------------------------------------------------------------------------- //
// This is an auto generated file created by the Knowit.KEX.TypescriptGenerator.       //
// If changes has been made to the original .cs file that should be included,          //
// run the Knowit.KEX.TypescriptGenerator.exe to get the latest changes for the model. //
// ----------------------------------------------------------------------------------- //

enum FeatureTypeEnum {
  BecomeMember = 0,
}

export default FeatureTypeEnum;

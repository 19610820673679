// Import necessary libraries and utilities for managing global settings in the Redux store
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { useSelector } from 'react-redux';

import SorterModel from 'Models/Search/SorterModel.interface';
import {
  mapUrlToFilterState,
  mapUrlToSortersState,
  resetRangeFilters,
  updateQueryParams,
} from 'app/shared/filter/filterReducerHelperFunctions';

import { FilterAction } from 'app/shared/filter/filterTypes';
import { AtLeastOne } from 'shared/types/types';
import FacetModel from './models/Facet';
import { getSelectedModel } from '../../helpers/searchHelper';
import { firstLetterCapital } from '../../helpers/stringHelper';

export type RangeType = {
  currentMin: string;
  currentMax: string;
  min: string;
  max: string;
};

export type SelectFilter = {
  name: string;
  value: boolean;
  key: string;
};

export const defaultTake = 12;
export const getParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const sortOrderParam = searchParams.get('sortOrder');
  const skipParam = Number(searchParams.get('skip') ?? 0);
  const takeParam = Number(searchParams.get('take') ?? defaultTake);
  const instockParam = searchParams.get('instock') ?? 'false';

  return { skipParam, takeParam, instockParam, sortOrderParam };
};

const getInitParams = () => {
  const { instockParam, sortOrderParam } = getParams();
  const searchParams = new URLSearchParams(window.location.search);
  const multiSelectFilters: Record<string, string[]> = {};
  const params: [string, string][] = []; // Start with an empty array

  // Conditionally add sortOrderParam
  if (sortOrderParam) {
    params.push(['sortOrder', sortOrderParam]);
  }

  if (instockParam === 'true') {
    params.push(['instock', 'true']);
  }
  searchParams.forEach((value, key) => {
    params.push([key, value]);

    const values = value.split(';');
    multiSelectFilters[key] = values.length > 0 ? values : [];
  });

  return { params, multiSelectFilters };
};
const { params, multiSelectFilters } = getInitParams();

/**
 * Represents the state of the filter feature in the application.
 * @typedef {Object} FilterState
 * @property {Object | null} data - The filter data containing facets and sorters.
 * @property {Map<string, Set<string>>} multiSelectFilters - The multi-select filters.
 * @property {Map<string, RangeType>} rangeFilters - The range filters.
 * @property {SorterModel} sorterFilter - The sorter filter.
 * @property {SorterModel} initSorterFilter - The initial sorter filter.
 * @property {[string, string][]} queryParams - The query parameters.
 * @property {string} query - The query string.
 * @property {string} selectedModel - The selected model.
 */

export type FilterState = {
  facets?: FacetModel[];
  sorters: SorterModel[];
  multiSelectFilters: Record<string, string[]>;
  rangeFilters: Record<string, RangeType>;
  sorterFilter?: SorterModel;
  initSorterFilter: SorterModel;
  queryParams: [string, string][];
  query: string;
  selectedModel: string;
  inStock: boolean;
  numberOfHits: number;
  loading?: boolean;
};

/**
 * Initial state for global settings.
 */
const initialState: FilterState = {
  sorters: [],
  multiSelectFilters: multiSelectFilters,
  rangeFilters: {},
  initSorterFilter: {
    selected: true,
    key: 'sortOrderLatest',
    value: '0',
  },
  queryParams: params,
  query: '', //TODO: get from global query locig removed
  selectedModel: getSelectedModel(),
  inStock: false,
  numberOfHits: 0,
  loading: false,
};
/**
 * Redux slice managing global settings within the app.
 */
export const filterSlice = createSlice({
  name: 'filter', // Unique name for this slice
  initialState, // Setting the initial state
  reducers: {
    toggleLoading: (state) => {
      state.loading = !state.loading;
    },
    updateFilterState: (
      state,
      action: PayloadAction<AtLeastOne<FilterState>>
    ) => {
      return {
        ...state,
        ...action.payload,
      }; // Utility to merge new values into the state
    },
    onCategoryChange: (state) => {
      state.selectedModel = '';
      state.multiSelectFilters = multiSelectFilters;
      state.rangeFilters = {};
      state.sorterFilter = state.initSorterFilter;
    },
    onClear: (state) => {
      const oldParams = new URLSearchParams(window.location.search);
      const searchParams = new URLSearchParams('');
      const query = oldParams.get('query');
      if (query) searchParams.set('query', query);
      const newUrl =
        searchParams && searchParams.toString()
          ? `${window.location.pathname}?${searchParams.toString()}`
          : window.location.pathname;

      if (window.location.pathname !== newUrl)
        window.history.replaceState(null, '', newUrl);
      const { params } = getInitParams();
      const newState = {
        ...state,
        multiSelectFilters: {
          ...multiSelectFilters, // Spread existing multiSelectFilters
        },
        rangeFilters: resetRangeFilters(initialState.rangeFilters),
        queryParams: params,
        inStock: false,
      };

      return newState;
    },
    onChangeCategory: (state) => {
      state.selectedModel = '';
      state.multiSelectFilters = {};
      state.rangeFilters = {};
      state.sorterFilter = state.initSorterFilter;
    },
    setMultiSelectFilter: (
      state,
      action: PayloadAction<FilterAction<'setMultiSelectFilter'>>
    ) => {
      const { checked, key, value } = action.payload;

      // Initialize or retrieve existing filter values from the state
      let filterValues = state.multiSelectFilters[key] || [];

      // Handle the case where the filter is checked or unchecked
      if (checked) {
        filterValues.push(value);
      } else {
        // If unchecked, remove the value from the filter values
        filterValues = filterValues.filter((v) => v !== value);
      }

      // Update the state with the new filter values
      state.multiSelectFilters[key] = filterValues;

      // Update the queryParams state
      state.queryParams = state.queryParams.filter(
        (param: [string, string]) => param[0] !== key
      );

      // If there are still filter values, add them back to the queryParams
      if (filterValues.length) {
        state.queryParams = [
          ...state.queryParams,
          [key, filterValues.join(';')],
        ];
      }

      // Now handle URL updates
      const searchParams = new URLSearchParams(window.location.search);

      if (filterValues.length > 0) {
        // If there are still values for the filter, update the URL parameter
        searchParams.set(key, filterValues.join(';'));
      } else {
        // If no values are left for the filter, remove the filter from the URL
        searchParams.delete(key);
      }

      // Update the URL without reloading the page

      const newUrl = searchParams.toString()
        ? `${window.location.pathname}?${searchParams.toString()}`
        : window.location.pathname;

      window.history.replaceState(null, '', newUrl);
    },
    setRangeFilter: (
      state,
      action: PayloadAction<FilterAction<'setRangeFilter'>>
    ) => {
      const { key, currentMin, currentMax, min, max } = action.payload;
      state.rangeFilters[key] = { currentMin, currentMax, min, max };
      state.queryParams = updateQueryParams(
        state.queryParams,
        'min' + firstLetterCapital(key),
        currentMin
      );
      state.queryParams = updateQueryParams(
        state.queryParams,
        'max' + firstLetterCapital(key),
        currentMax
      );
    },
    setNumberOfHits: (
      state,
      action: PayloadAction<FilterAction<'setNumberOfHits'>>
    ) => {
      state.numberOfHits = action.payload.value;
    },
    setSorterFilter: (
      state,
      action: PayloadAction<FilterAction<'setSorterFilter'>>
    ) => {
      state.sorterFilter = action.payload.value;
    },
    setMultiSelectFilters: (
      state,
      action: PayloadAction<FilterAction<'setMultiSelectFilters'>>
    ) => {
      state.multiSelectFilters = action.payload.selectFilters;
    },
    setQuery: (state, action: PayloadAction<FilterAction<'setQuery'>>) => {
      state.query = action.payload.value;
    },
    setSorterFilterByText: (
      state,
      action: PayloadAction<FilterAction<'setSorterFilterByText'>>
    ) => {
      const sorter =
        state.sorters &&
        state.sorters.find((element) => element.value === action.payload.value);
      state.sorterFilter = sorter || state.sorterFilter;
    },
    setSelectedModel: (
      state,
      action: PayloadAction<FilterAction<'setSelectedModel'>>
    ) => {
      state.selectedModel = action.payload.value;
    },
    setFacets: (state, action: PayloadAction<FilterAction<'setFacets'>>) => {
      // Assuming parseDataToFilter is a function defined elsewhere that updates the state based on data

      state.facets = action.payload.facets;
      state.sorters = action.payload.sorters;
      state = mapUrlToSortersState(action.payload.sorters, state);
      state = mapUrlToFilterState(action.payload.facets, state);
    },
    setQueryParams: (
      state,
      action: PayloadAction<FilterAction<'setQueryParams'>>
    ) => {
      const { key, value } = action.payload;
      state.inStock = key === 'instock' ? value === 'true' : !!state.inStock;

      state.queryParams = state.queryParams?.filter(
        (param: [string, string]) => param[0] !== key
      );
      if (key !== 'instock') {
        state.queryParams.push([key, value]);
      } else {
        value === 'true' && state.queryParams.push([key, value]);
      }
      const searchParams = new URLSearchParams(window.location.search);
      if (value === 'true') {
        // If there are still values for the filter, update the URL parameter
        searchParams.set(key, 'true');
      } else {
        // If no values are left for the filter, remove the filter from the URL
        searchParams.delete(key);
      }
      // Update the URL without reloading the page
      const newUrl = searchParams.toString()
        ? `${window.location.pathname}?${searchParams.toString()}`
        : window.location.pathname;
      if (window.location.pathname !== newUrl)
        window.history.pushState(null, '', newUrl);
    },
  },
});

// Destructure and export actions
export const {
  toggleLoading,
  onClear,
  onCategoryChange,
  setMultiSelectFilter,
  setRangeFilter,
  setSorterFilter,
  setMultiSelectFilters,
  setQuery,
  setSorterFilterByText,
  setSelectedModel,
  setFacets,
  updateFilterState,
  setQueryParams,
  setNumberOfHits,
} = filterSlice.actions;

/**
 * Custom hook to get the current global settings state from the Redux store.
 */
export const useFilterState = () =>
  useSelector((state: RootState) => state && state.filter);

// Export the reducer for setting up the Redux store
export default filterSlice.reducer;

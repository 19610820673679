import { createSlice } from '@reduxjs/toolkit';
import { submitOrder } from './order';
import { RootState, useAppDispatch } from 'app/store';
import { CreateOrderRequestModel } from './OrderModel';
import { useSelector } from 'react-redux';

export interface CreateOrderState {
  redirectUrl: string;
  isLoading: boolean;
}

const initialState: CreateOrderState = {
  redirectUrl: '',
  isLoading: false,
};

export const orderSlice = createSlice({
  name: 'createOrder', // Name of the slice
  initialState, // Initial state for this slice
  reducers: {},
  extraReducers: (builder) => {
    // Handling the fulfilled state of fetchNavigationStateAsync
    builder.addCase(submitOrder.fulfilled, (state, action) => {
      const result = action.payload;

      if (result?.isSuccess) {
        return { ...state, redirectUrl: result.data, isLoading: false };
      }
      return { ...state, redirectUrl: '', isLoading: false };
    });
    builder.addCase(submitOrder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(submitOrder.rejected, (state) => {
      state.isLoading = false;
      state.redirectUrl = '';
    });
  },
});

/**
 * Action to submit an order
 *
 * @param {CreateOrderRequestModel} payload - The order payload
 */
export const useCreateOrder = () => {
  const dispatch = useAppDispatch();

  return (payload: CreateOrderRequestModel) => dispatch(submitOrder(payload));
};

export const useCreatingOrder = () =>
  useSelector((state: RootState) => state.order);

// Exporting the reducer as the default export
export default orderSlice.reducer;

import SorterModel from 'Models/Search/SorterModel.interface';
import { FILTER_URL_CONSTANTS } from 'app/helpers/searchHelper';
import { FilterState, RangeType } from 'app/shared/filter/filterSlice';
import { firstLetterCapital } from '../../helpers/stringHelper';
import { ObjectKeys } from '../utils/utils';
import FacetModel from './models/Facet';

export const resetRangeFilters = (
  rangeFilters: Record<string, RangeType>,
  facets?: FacetModel[]
): typeof rangeFilters => {
  if (!facets) {
    return {};
  }

  const newRange = rangeFilters;

  ObjectKeys(rangeFilters)
    .map(
      (key) => [key, facets.find((element) => element.name === key)] as const
    )
    .filter((entry): entry is [string, FacetModel] => !!entry[1])
    .forEach(
      ([
        // eslint-disable-next-line
        _,
        {
          ranges: [{ min, max }],
        },
      ]) => {
        newRange['key'] = {
          min: min.toString(),
          max: max.toString(),
          currentMin: min.toString(),
          currentMax: max.toString(),
        };
      }
    );

  return newRange;
};

export const toQueryParams = (queryString: string) => {
  return Array.from(new URLSearchParams(queryString));
};

export const mapUrlToFilterState = (
  facets: FacetModel[],
  state: FilterState
) => {
  const url = new URL(window.location.href);
  // Extracting the search parameters from the URL
  const params = new URLSearchParams(url.search);
  const multiSelectFilters = state.multiSelectFilters || {};
  const rangeFilters = state.rangeFilters || {};
  facets?.forEach((facet) => {
    if (facet.type === 0) {
      const facetFilter = params.get(facet.name);
      if (facetFilter) {
        multiSelectFilters[facet.name]
          ? !multiSelectFilters[facet.name].includes(facetFilter) &&
            multiSelectFilters[facet.name].push(facetFilter)
          : (multiSelectFilters[facet.name] = [facetFilter]);
        state.multiSelectFilters = multiSelectFilters;
        state.queryParams.push([facet.name, facetFilter]);
      }
    } else if (facet.type === 1) {
      const minQuery = `min${firstLetterCapital(facet.name)}`;
      const maxQuery = `max${firstLetterCapital(facet.name)}`;
      const minQueryFacet = params.get(minQuery);
      const maxQueryFacet = params.get(maxQuery);
      minQueryFacet &&
        (state.queryParams = updateQueryParams(
          state.queryParams,
          minQuery,
          minQueryFacet
        ));
      maxQueryFacet &&
        (state.queryParams = updateQueryParams(
          state.queryParams,
          maxQuery,
          maxQueryFacet
        ));

      const range = rangeFilters[facet.name as keyof RangeType] || {
        currentMin: minQueryFacet,
        currentMax: maxQueryFacet,
        min: '',
        max: '',
      };
      rangeFilters[facet.name] = range;
      state.rangeFilters = rangeFilters;
    }
  });
  if (params.get(FILTER_URL_CONSTANTS.IN_STOCK) === 'true') {
    state.inStock = true;
  }
  return state;
};

export const mapUrlToSortersState = (
  sorters: SorterModel[],
  state: FilterState
) => {
  const url = new URL(window.location.href);
  // Extracting the search parameters from the URL
  const params = new URLSearchParams(url.search);
  state.sorters = sorters.map((sort) => {
    if (params.get(FILTER_URL_CONSTANTS.ORDER) === sort.value.toString()) {
      state.sorterFilter = sort;
    }
    return {
      ...sort,
      selected:
        params.get(FILTER_URL_CONSTANTS.ORDER) === sort.value.toString(),
    };
  });
  return state;
};

export const updateQueryParams = (
  queryParams: [string, string][],
  key: string,
  value: string
) => {
  queryParams = queryParams?.filter(
    (param: [string, string]) => param[0] !== key
  );
  queryParams.push([key, value]);
  return queryParams;
};

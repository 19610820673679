import { plainState } from 'app/store';
import { useState } from 'react';
import styled from 'styled-components';
import Litium from './systemLogo/Litium';
import Norce from './systemLogo/Norce';
import Opti from './systemLogo/Opti';

export interface SystemProps {
  system: string;
  api: string;
}

const conf = {
  Litium: {
    system: import.meta.env.VITE_LITIUM,
    api: import.meta.env.VITE_LITIUM_API,
  },
};

export type SystemKeys = keyof typeof conf;

function SystemSwitch() {
  const [currentCms, setCms] = useState<string | undefined>(
    plainState.get('cms')
  );
  const [currentCommerce, setCommerce] = useState<string | undefined>(
    plainState.get('commerce')
  );

  const switchSystem = (cms: string, commerce: string) => {
    setCms(cms as string);
    setCommerce(commerce as string);
    plainState.set('api', conf[cms as keyof typeof conf].api as string);
    saveApiInSession(conf[cms as keyof typeof conf].api);
    saveCmsInSession(cms);
    saveCommerceInSession(commerce);
  };

  return (
    <Root>
      <Systems>
        <button
          onClick={() =>
            switchSystem(
              import.meta.env.VITE_UMBRACO,
              import.meta.env.VITE_NORCE
            )
          }
        >
          <Norce active={currentCommerce === 'Norce'} />
        </button>
        <button
          onClick={() =>
            switchSystem(
              import.meta.env.VITE_OPTIMIZELY_CMS,
              import.meta.env.VITE_OPTIMIZELY_COMMERCE
            )
          }
        >
          <Opti active={currentCms === 'OptimizelyCms'} />
        </button>
        <button
          onClick={() =>
            switchSystem(
              import.meta.env.VITE_LITIUM,
              import.meta.env.VITE_LITIUM
            )
          }
        >
          <Litium active={currentCms === 'Litium'} />
        </button>
      </Systems>
    </Root>
  );
}

const saveApiInSession = (api: string) => {
  sessionStorage.setItem('api', api);
};

const saveLanguageiInSession = (api: string) => {
  sessionStorage.setItem('language', api);
};

export const saveCmsInSession = (system: string) => {
  plainState.set('cms', system);
  sessionStorage.setItem('cms', system);
};

export const saveCommerceInSession = (system: string) => {
  plainState.set('commerce', system);
  sessionStorage.setItem('commerce', system);
};

export const saveMiddleWare = (toggle: boolean) => {
  plainState.set('middleware', toggle);
  saveApiInSession(import.meta.env.VITE_STOREFRONT_API_KEY);
  plainState.set('api', import.meta.env.VITE_STOREFRONT_API_KEY);
};

export const getAPIBasedOnSystem = () => {
  return import.meta.env.VITE_STOREFRONT_API_KEY;
};

export const getCmsFromSession = () => {
  return import.meta.env.VITE_TARGET_CMS;
};

export const getCommerceFromSession = () => {
  return import.meta.env.VITE_TARGET_COMMERCE;
};

export const getLanguageFromSession = () => {
  const paths = window.location.pathname.split('/');
  const language =
    sessionStorage.getItem('language') ?? (paths && paths[1]) ?? 'en';

  if (language) saveLanguageiInSession(language);

  return language;
};

export const getApiFromSession = () => {
  const api =
    sessionStorage.getItem('api') ?? import.meta.env.VITE_STOREFRONT_API_KEY;

  if (api) saveApiInSession(api);

  return api;
};

const Root = styled.div`
  display: flex;
  gap: 16px;
  position: fixed;
  padding: 8px;
  border-radius: 8px;
  bottom: 16px;
  right: 16px;
  svg {
    path {
      transition: all 0.3s ease-in-out;
    }
  }
  background-color: ${({ theme }) => theme.colors.onInteractiveEmphasized};
`;

const Systems = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space.s75};
  align-items: center;
`;

export default SystemSwitch;

import LineItemBase from 'Commerce/shared/lineItems/lineItemBase';
import { getTheme } from 'shared/theme/ThemeProvider';
import { LineItemModelEXT } from 'app/shared/cart/cartSlice';
import { canUseDOM } from 'app/helpers/windowHelper';
import { WithArbitraryProps } from 'shared/types/types';
import { HTTP_CODE_200 } from './DataLayerConstants';
import { GTMEventName, pushEventToGTM } from './gtm';
import { PriceHelpers } from 'app/shared/globalSettings/priceHelpers';

type RequiredPageViewProps = Record<'pageTitle' | 'url', string>;
type PageViewProps = WithArbitraryProps<RequiredPageViewProps>;

export const pushGTMPageView = (
  page: PageViewProps,
  authenticated?: boolean
) => {
  if (canUseDOM()) {
    const { pageTitle, url } = page;
    const providedUrl =
      url?.length > 10 && url.startsWith('http') ? url : 'http://unknown';
    const pageUrl = new URL(providedUrl);

    pushEventToGTM('virtual_pageview', {
      page_title: pageTitle,
      page_location: pageUrl.href,
      page_path: pageUrl.pathname,
      page_referrer: window.document.referrer ?? '',
      status_code: HTTP_CODE_200,
      logged_in: authenticated ?? false,
      screen_mode: getTheme() ?? 'light',
      user_type: 'private',
    });
  }
};

export const pushGTMEventEcommerce = (
  eventName: GTMEventName,
  currency: string,
  totalPrice: string,
  lineItems: LineItemModelEXT<LineItemBase>[],
  getPrice: ReturnType<PriceHelpers['getPrice']>
) => {
  pushEventToGTM(eventName, {
    ecommerce: {
      currency: currency,
      value: totalPrice,
      items: lineItems.map((item) => ({
        item_id: item.code,
        item_name: item.item.name,
        item_category: item.item.productType,
        price: getPrice(item.price.unitPrice),
        quantity: item.quantity,
      })),
    },
  });
};

export const pushGTMFilterEvent = (
  eventName: GTMEventName,
  filter: string,
  filterType: string
) => {
  pushEventToGTM(eventName, {
    filter: filter,
    filter_type: filterType,
  });
};

/*
Examples:

pushGTMPageView({
  url: 'https://some.domain.net/some/path/to/page',
  pageTitle: 'Page title'
});

pushGTMPageView({
  url: 'https://some.domain.net/some/path/to/page',
  pageTitle: 'Page title',
  arbitraryJunk: {
    someKey: '"arbitraryJunk" is ignored in function pushGTMPageView',
    anotherKey: undefined
  },
});
*/

import styled, { CSSProp } from 'styled-components';

export interface SkeletonLoaderProps {
  children: React.ReactNode;
  css?: CSSProp;
}

const SkeletonLoader = ({ children, css }: SkeletonLoaderProps) => {
  return <SkeletonWrapper css={css}>{children}</SkeletonWrapper>;
};

export default SkeletonLoader;

const SkeletonWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['css'].includes(prop),
})<{ css?: CSSProp }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  .skeletonLoader {
    background-image: ${({ theme }) =>
      theme.id === 'dark'
        ? `linear-gradient( 270deg,
                    rgba(54, 54, 54, 0) 40%,
                  rgba(92, 92, 92, 0.5) 50%,
                    rgba(79, 79, 79, 0) 60%
                  );
                `
        : `linear-gradient( 270deg,
                    rgba(255, 255, 255, 0) 40%,
                  rgba(255, 255, 255, 0.5) 50%,
                    rgba(255, 255, 255, 0) 60%
                  ); 
                  `};
    background-color: ${({ theme }) => theme.colors.decorationBackgroundOne};
    ${({ theme }) => theme.animations.cssAnimations.shimmerSkeletonAnimation};
    border-radius: 0.25rem;
    background-size: 200% 100%;
    background-position-x: 300%;
  }
  && {
    ${({ css }) => css};
  }
`;

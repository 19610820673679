import { createSlice } from '@reduxjs/toolkit';
import { RootState, useAppDispatch } from 'app/store';
import { useSelector } from 'react-redux';
import { LineItemModelEXT } from '../cart/cartSlice';
import { fetchFavoriteStateAsync, updateFavoriteStateAsync } from './favorites';

/**
 * Interface defining the structure of the favorites state.
 */
export interface FavoritesState {
  items: LineItemModelEXT[];
  hasFavorites: boolean;
  isLoading: boolean;
}

/**
 * Interface describing the details required to update item to favorites.
 */
export interface UpdateFavorite {
  code: string; // Product code identifier for the item
  quantity: number;
}

// Setting default values for the cart state
export const initialState: FavoritesState = {
  items: [],
  hasFavorites: false,
  isLoading: false,
};

/**
 * Slice for managing favorites-related operations in the Redux store.
 */
export const favoritesSlice = createSlice({
  name: 'favorites', // Unique identifier for this slice
  initialState, // Default state values for this slice
  reducers: {
    toggleFavoritesLoading: (state) => {
      state.isLoading = !state.isLoading;
    },
  }, // Contains direct mutative reducer logic (currently none)
  extraReducers: (builder) => {
    // Responding to fetchFavoriteStateAsync's successful completion
    builder.addCase(fetchFavoriteStateAsync.fulfilled, (state, action) => {
      const result = action.payload;

      if (result?.isSuccess) {
        return result.data;
      }
      return state;
    });
    // Responding to fetchFavoriteStateAsync's initiation
    builder.addCase(fetchFavoriteStateAsync.pending, (state) => {
      return {
        ...state,
        isLoading: true,
      };
    });
    // Responding to updateFavoriteStateAsync's successful completion
    builder.addCase(updateFavoriteStateAsync.fulfilled, (state, action) => {
      const result = action.payload;

      if (result?.isSuccess) {
        return result.data;
      }
      return state;
    });
    builder.addCase(updateFavoriteStateAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateFavoriteStateAsync.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

/**
 * Action to update the cart based on the provided item details.
 *
 * @param {UpdateFavorite} item - Details of the item to update
 */
export const useUpdateFavorites = () => {
  const dispatch = useAppDispatch();
  return (item: UpdateFavorite) => dispatch(updateFavoriteStateAsync(item));
};

export const useFetchFavoritesState = () => {
  const dispatch = useAppDispatch();
  return () => dispatch(fetchFavoriteStateAsync());
};

const { toggleFavoritesLoading } = favoritesSlice.actions;

export { toggleFavoritesLoading };
/**
 * Hook to retrieve the current favorite state from the Redux store.
 */
export const useFavorites = () =>
  useSelector((state: RootState) => state.favorites);

// Exporting the main reducer function for the cart slice
export default favoritesSlice.reducer;

import { configureStore } from '@reduxjs/toolkit';
import categorySearchReducer from 'Commerce/Pages/CategoryPage/categorySearchSlice';
import {
  getApiFromSession,
  getCmsFromSession,
  getCommerceFromSession,
  getLanguageFromSession,
} from 'DesignSystem/StyledComponets/Settings/SystemSwitch';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { auth } from 'shared/auth/api/authenticate';
import quickSearchReducer from '../shared/quickSearch/slice/quickSearchSlice';
import searchReducer from '../shared/search/slice/searchSlice';
import dialogReducer from '../shared/slice/dialog/dialogSlice';
import uiReducer from '../shared/slice/ui/uiSlice';
import appErrorReducer from './shared/appError/appErrorSlice';
import { fetchCartStateAsync } from './shared/cart/cart';
import cartReducer from './shared/cart/cartSlice';
import checkoutReducer from './shared/checkout/checkoutSlice';
import { fetchCurrentPageAsync } from './shared/currentPage/currentPage';
import currentPageReducer from './shared/currentPage/currentPageSlice';
import { fetchCustomerStateAsync } from './shared/customer/customer';
import customerStateReducer from './shared/customer/customerSlice';
import { fetchFavoriteStateAsync } from './shared/favorites/favorites';
import favoritesReducer from './shared/favorites/favoritesSlice';
import filterReducer from './shared/filter/filterSlice';
import footerReducer from './shared/footer/footerSlice';
import genericDataReducer from './shared/genericData/genericSlice';
import { fetchGlobalStateAsync } from './shared/globalSettings/globalSettings';
import globalSettingsReducer from './shared/globalSettings/globalSettingsSlice';
import headerReducer from './shared/header/headerSlice';
import AbortControllers from './shared/models/AbortControllers';
import KeyValueStore from './shared/models/KeyValueStore';
import { fetchNavigationStateAsync } from './shared/navigation/navigation';
import navigationReducer from './shared/navigation/navigationSlice';
import orderReducer from './shared/order/orderSlice';
import orderHistoryReducer from './shared/orderHistory/orderHistorySlice';
import bulkUpdateCartReducer from './shared/quickOrder/bulkUpdateCartSlice';
import codeSearchReducer from './shared/quickOrder/codeSearchSlice';
import { fetchTranslationStateAsync } from './shared/translations/translations';
import translationsReducer from './shared/translations/translationsSlice';

export const store = configureStore({
  reducer: {
    appError: appErrorReducer,
    bulkUpdateCart: bulkUpdateCartReducer,
    cart: cartReducer,
    categorySearch: categorySearchReducer,
    checkoutStepsData: checkoutReducer,
    codeSearch: codeSearchReducer,
    currentPage: currentPageReducer,
    customerState: customerStateReducer,
    dialog: dialogReducer,
    favorites: favoritesReducer,
    filter: filterReducer,
    footerData: footerReducer,
    genericData: genericDataReducer,
    globalSettings: globalSettingsReducer,
    headerData: headerReducer,
    navigation: navigationReducer,
    order: orderReducer,
    orderHistory: orderHistoryReducer,
    quickSearch: quickSearchReducer,
    search: searchReducer,
    translations: translationsReducer,
    ui: uiReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these field paths in all actions
        ignoredActions: [
          'cart/update/fetch/pending',
          'cart/update/fetch/fulfilled',
          'cart/update/quantity/pending',
          'cart/update/quantity/fulfilled',
          'uiState/updateComponentsState',
        ],
        ignoredActionPaths: [
          'payload.display',
          'payload.dispatch',
          'payload.openerElement',
          'payload.modalContentComponent',
          'payload.modalFooterComponent',
          'payload.ChangePasswordModal.onClick',
        ],
        ignoredPaths: [
          'filter.multiSelectFilters',
          'filter.rangeFilters',
          'ui.modalState.display',
          'ui.modalState.openerElement',
          'ui.modalState.modalContentComponent.component',
          'ui.modalState.modalFooterComponent',
          'ui.modalState.modalFooterComponent.modalFooterContent.additionalInfo',
          'ui.modalState.componentsState.ChangePasswordModal.onClick',
        ],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// Store values that needs to be accessed outside of hooks
export interface PlainStateSchema {
  language: string;
  system: string;
  cms: string;
  commerce: string;
  middleware: boolean;
  api: string;
  500: string;
  404: string;
  token: string;
}

export const plainState = new KeyValueStore<PlainStateSchema>({
  language: getLanguageFromSession(),
  api: getApiFromSession(),
  cms:
    import.meta.env.VITE_USE_MIDDLELAYER === 'true'
      ? getCmsFromSession()
      : import.meta.env.VITE_TARGET_CMS,
  commerce:
    import.meta.env.VITE_USE_MIDDLELAYER === 'true'
      ? getCommerceFromSession()
      : import.meta.env.VITE_TARGET_COMMERCE,
  middleware: import.meta.env.VITE_USE_MIDDLELAYER === 'true',
  500: '',
  404: '',
  token: '',
});

export const abortControllers = new AbortControllers();

export const fetchResources = (dispatch: AppDispatch) => {
  // Fetch resources when we have global settings
  dispatch(fetchNavigationStateAsync());
  dispatch(fetchCustomerStateAsync());
  dispatch(fetchCartStateAsync());
  dispatch(fetchCurrentPageAsync());
  dispatch(fetchFavoriteStateAsync());
  dispatch(fetchTranslationStateAsync());
};

export const fetchSessionResources = (dispatch: AppDispatch) => {
  dispatch(fetchCustomerStateAsync());
  dispatch(fetchCartStateAsync());
  dispatch(fetchFavoriteStateAsync());
};

// App starts with fetching global settings. When that is done, we fetch the rest of the resources
export const init = (dispatch: AppDispatch) => {
  dispatch(fetchGlobalStateAsync({}));
  plainState.subscribe('cms', () => {
    if (plainState.get('cms') !== 'Litium')
      auth().then(() => dispatch(fetchGlobalStateAsync({})));
    else dispatch(fetchGlobalStateAsync({}));
  });
};

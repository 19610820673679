import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import {
  lazyModalViews,
  ModalContentViews,
  ModalViewTypes,
} from 'DesignComponents/Organisms/Modal/ModalContentViews';
import {
  lazyModalFooterViews,
  ModalFooterViews,
  ModalFooterViewTypes,
} from 'DesignComponents/Organisms/Modal/ModalFooterViews';
import { useAppSelector } from 'app/store';
import { JSX } from 'react';
import { GetComponentProps } from 'shared/types/types';

export type ModalViewId =
  | keyof typeof ModalContentViews
  | 'ExampleOneStory'
  | 'ExampleTwoStory'
  | 'MiniCartStory';

export type ComponentsState = Partial<Record<ModalViewId, unknown>>;
export interface ModalUiState {
  display: boolean;
  viewId?: ModalViewId;
  openerElement?: HTMLElement;
  title?: string;
  modalContentComponent?: {
    component?: keyof lazyModalViews<keyof typeof ModalContentViews>;
    props: GetComponentProps<ModalViewTypes>;
    noContentPadding?: boolean;
  };
  modalFooterComponent?: {
    component: keyof lazyModalFooterViews<keyof typeof ModalFooterViews>;
    props?: GetComponentProps<ModalFooterViewTypes>;
    Element?: JSX.Element;
  };
  componentsState: ComponentsState;
  noTopGap?: boolean;
}

type UserMenuState = {
  userMenuIsOpen: boolean;
  query: string;
};

type UiStateType = {
  modalState: ModalUiState;
  userMenuState: UserMenuState;
};

// Define the initial state using the UiStateType
const initialState: UiStateType = {
  modalState: {
    display: false,
    componentsState: {},
  },
  userMenuState: {
    userMenuIsOpen: false,
    query: '',
  },
};

// Create the slice
const uiStateSlice: Slice<UiStateType> = createSlice({
  name: 'uiState',
  initialState: initialState,
  reducers: {
    // Define a case reducer for the 'toggleModal' action
    toggleModal: (state, action: PayloadAction<ModalUiState>) => {
      // Handle focus restoration
      if (!action.payload.display && state.modalState.openerElement) {
        state.modalState.openerElement.focus();
      }

      return {
        ...state,
        modalState: {
          ...action.payload,
        },
      };
    },
    updateComponentsState: (state, action: PayloadAction<ComponentsState>) => {
      state.modalState.componentsState = {
        ...state.modalState.componentsState,
        ...action.payload,
      };
    },
  },
});

// Export the action creator for toggleModal
export const { toggleModal, setQuery, updateComponentsState } =
  uiStateSlice.actions;

export const useModalState = () =>
  useAppSelector(
    (state) =>
      state.ui && ((state.ui as UiStateType).modalState as ModalUiState)
  );

export const useUserMenuState = () =>
  useAppSelector<UserMenuState>(
    (state) =>
      state.ui && ((state.ui as UiStateType).userMenuState as UserMenuState)
  );

// Export the custom hooks using the useSelector and useDispatch hooks from 'react-redux'
// ... (your existing useGlobalSettings and useGlobalSettingsDispatch hooks code here)

// Export the reducer
export default uiStateSlice.reducer;

// Importing type utilities

import { AtLeastOne } from 'shared/types/types';

/**
 * Patches an original object model with specified changes.
 * @template T - A generic type that extends from an object with string keys.
 *
 * @param {T} original - The original object that needs to be patched.
 * @param {AtLeastOne<T>} changes - An object representing changes with at least one field from T.
 *
 * @returns {T} - Returns a new object that is a merge of the original and changes.
 *
 * @example
 * const user = { name: 'John', age: 30 };
 * const updatedUser = patchModel(user, { age: 31 });
 */
// NOTE: This function will be moved to a shared location later
// eslint-disable-next-line
export function patchModel<T extends Record<string, any>>(
  original: T,
  changes: AtLeastOne<T>
): T {
  return Object.assign({}, original, changes);
}

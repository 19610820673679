import ColorTokensType from '../Settings/colorTypes';

export type ColorType = keyof typeof darkColors;

const darkColors: ColorTokensType = {
  //#region Universal
  white: '#',

  //#region Base - Used for static backgrounds and borders
  surface: '#27262d',
  decorationBackgroundOne: '#35353C',
  decorationSubtle: '#b3b3b7',
  decorationEmphasized: '#5A6967',
  inverse: '#2a2e33',
  //#endregion
  //#region Text and icon
  onSurface: '#f5f6f6',
  onElevation: '#f5f6f6',
  onSurfaceSubtle: '#ACB3B3',
  onSurfaceSubtleSecondary: '#F5F6F6',
  onInteractivePrimary: '#f8f9f9',
  onInteractiveSecondary: '#f5f6f6',
  onInteractiveEmphasized: '#353f3e',
  onInverse: '#f5f6f6',
  onStatic: '#1B1F1F',
  onWarning: '#353F3E',
  //#endregion
  //#region Semantic
  semanticSuccess: '#90d3ac',
  semanticInformative: '#9acbff',
  semanticError: '#ffb4ab',
  semanticWarning: '#AF3500',
  semanticWarningHover: '#922C00',
  semanticWarningPressed: '#752300',
  //#endregion
  //#region Elevation
  elevateOne: '#3e3e43',
  elevateTwo: '#35353B',
  elevateThree: '#2E2E34',

  overlayOne: '#FFFFFFB3',
  underlayOne: '#00000066',
  //#endregion
  //#region Interactive primary background
  interactivePrimaryBackgroundDefault: '#4F7975',
  interactivePrimaryBackgroundHover: '#496F6C',
  interactivePrimaryBackgroundPressed: '#426662',
  //#endregion
  //#region Interactive Secondary background
  interactiveSecondaryBackgroundDefault: 'transparent',
  interactiveSecondaryBackgroundHover: '#302F35',
  interactiveSecondaryBackgroundPressed: '#38373E',
  //#endregion
  //#region Interactive Emphasised background
  interactiveEmphasizedBackgroundDefault: '#FBBFAB',
  interactiveEmphasizedBackgroundHover: '#F9A78B',
  interactiveEmphasizedBackgroundPressed: '#F89A7B',
  //#endregion
  //#region Interactive Subtle background
  interactiveSubtleBackgroundDefault: '#757A7A',
  interactiveSubtleBackgroundHover: '#6c7070',
  interactiveSubtleBackgroundPressed: '#676b6b',
  //#endregion
  //#region  Gradient Opacity
  decorationGradientOpacity:
    'linear-gradient(181.09deg, rgba(0, 0, 0, 0) 0.93%, #1B1C1A 74.53%)',
  //#endregion
  //#region Interactive Border
  interactiveBorderActive: '#84cac3',
  interactiveBorderActiveInverse: '#7BA7A3',
  interactiveBorderSecondaryDefault: '#e1e2e2',
  interactiveBorderSecondaryHover: '#d8d8d8',
  interactiveBorderSecondaryPressed: '#cecfcf',
  interactiveBorderInverse: '#3F3F3F',
  interactiveBorderSelected: '#FFFFFF',
  //#endregion
  //#region Interactive Disabled
  interactiveDisabledOne: '#C9CBCD',
  interactiveDisabledTwo: '#808284',
  //#endregion
  //#region Static colors
  staticBrand1: '#C0D3D5',
  staticBrand2: '#FBBFAB',
  staticWhite: '#FFFFFF',
  staticBlack: '#000000',
  //#endregion
  //#region Link
  linkDefault: '#84CAC3',
  linkHover: '#AAE3DD',
  decorationEmphasizedStrong: '',
  decorationAccent: '',
  decorationAccentStrong: '',
  staticBrand3: '#F78F1E',
};

export default darkColors;

import { plainState } from 'app/store';
import { getApiFromSession } from 'DesignSystem/StyledComponets/Settings/SystemSwitch';

export const auth = () => {
  return fetch(getApiFromSession() + '/api/authenticate', {
    method: 'POST',
    headers: {
      'x-kex-middle-layer-authorization': import.meta.env.VITE_MIDDLELAYER_KEY,
      'X-BackendService': plainState.getValue(
        'cms',
        import.meta.env.VITE_TARGET_CMS
      ),
    },
  }).then(async (response) => {
    const clone = await response.clone().text();
    const parsedBody = clone ? await response.json() : undefined;
    await plainState.set('token', parsedBody);
  });
};

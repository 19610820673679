import { Price, PriceModel } from '../cart/cartSlice';

// Price with discount
const getPriceModel =
  (priceInclVAT: boolean) =>
  (priceModel: PriceModel | undefined | null): Price | undefined => {
    if (priceModel == undefined) return undefined;

    return priceInclVAT ? priceModel.priceIncVat : priceModel.priceExVat;
  };

// Price without discount
const getRegularPriceModel =
  (priceInclVAT: boolean) =>
  (priceModel: PriceModel | undefined | null): Price | undefined => {
    if (priceModel == undefined) return undefined;

    return priceInclVAT
      ? priceModel.regularPriceIncVat
      : priceModel.regularPriceExVat;
  };

// Discount amount
const getDiscountModel =
  (priceInclVAT: boolean) =>
  (priceModel: PriceModel | undefined | null): Price | undefined => {
    if (priceModel == undefined) return undefined;

    return (
      (priceInclVAT ? priceModel.discountIncVat : priceModel.discountExVat) ??
      undefined
    );
  };

const emptyPrice: Price = {
  amount: 0,
  formattedString: '',
};

// Price with discount
const getPrice =
  (priceInclVAT: boolean) => (priceModel: PriceModel | undefined | null) => {
    return getPriceModel(priceInclVAT)(priceModel)?.formattedString ?? '';
  };

// Price without discount
const getRegularPrice =
  (priceInclVAT: boolean) =>
  (priceModel: PriceModel | undefined | null, supressIfSame = true) => {
    if (supressIfSame && priceModel?.discountPercentage === 0) return '';

    return (
      getRegularPriceModel(priceInclVAT)(priceModel)?.formattedString ?? ''
    );
  };

// Discount amount
const getDiscount =
  (priceInclVAT: boolean) => (priceModel: PriceModel | undefined) => {
    return getDiscountModel(priceInclVAT)(priceModel)?.formattedString ?? '';
  };

export const priceHelpers = {
  getPriceModel,
  getRegularPriceModel,
  getDiscountModel,
  emptyPrice,
  getPrice,
  getRegularPrice,
  getDiscount,
};

export type PriceHelpers = typeof priceHelpers;

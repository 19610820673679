import { DefaultTheme } from 'styled-components';
import tokens from 'DesignSystem/StyledComponets/Settings/tokens';
import ColorTokensType from './Settings/colorTypes';

export enum Theme {
  dark = 'dark',
  light = 'light',
}

export type TokenTypes = typeof tokens & {
  colors: ColorTokensType & { transparent: 'transparent' };
  id: Theme;
};

export interface ThemeTypes extends DefaultTheme, TokenTypes {}

export type BasePropsTypes = {
  theme?: ThemeTypes;
};

import { mediaQueryTypes } from 'DesignSystem/StyledComponets/Settings/mediaQueries';
import React from 'react';
import styled, { CSSProp } from 'styled-components';

type Width = 'content' | 'full';

type PropType = {
  width?: Width;
  children: React.ReactNode;
  noPadding?: boolean;
  css?: CSSProp;
  as?: 'div' | 'section';
  id?: string;
};

function ContentContainer({
  width = 'content',
  children,
  noPadding,
  css,
  as = 'div',
  ...rest
}: PropType) {
  return (
    <Container as={as} width={width} css={css} noPadding={noPadding} {...rest}>
      {children}
    </Container>
  );
}

export default ContentContainer;

interface ContainerProps {
  width: Width;
  noPadding?: boolean;
  css?: CSSProp;
}

const Container = styled.div.withConfig({
  shouldForwardProp: (propName) =>
    !['width', 'noPadding', 'css'].includes(propName),
})<ContainerProps>`
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  // Apply max-width based on the width prop
  ${({ theme, width }) => {
    switch (width) {
      case 'content':
        return `max-width: ${theme.sizes.screenMaxWidth};`;
      case 'full':
        return 'max-width: auto;';
      default:
        return '';
    }
  }}

  // Apply padding based on the noPadding prop and screen size
  ${({ theme, noPadding }) => {
    if (noPadding) {
      return 'padding: 0;';
    }

    return `
      padding: 0 16px;

      @media ${mediaQueryTypes.bpMin376} {
        padding-left: ${theme.space.s150};
        padding-right: ${theme.space.s150};
      }

      @media ${mediaQueryTypes.bpMin721} {
        padding-left: ${theme.space.s300};
        padding-right: ${theme.space.s300};
      }

      @media ${mediaQueryTypes.bpMin1025} {
        padding-left: ${theme.space.s400};
        padding-right: ${theme.space.s400};
      }
    `;
  }}

  && {
    ${({ css }) => css};
  }
`;

import { lazy, Suspense } from 'react';
import { consoleLog } from './app/helpers/windowHelper';
import { store } from './app/store';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
const AppWithCallbackAfterRender = lazy(() => import('./main'));
const container = document.getElementById('root')!;
const root = createRoot(container, {
  onUncaughtError: (error, errorInfo) => {
    consoleLog('Uncaught error:', error, errorInfo);
  },
  onCaughtError: (error, errorInfo) => {
    consoleLog('Caught error:', error, errorInfo);
  },
});

root.render(
  <Provider store={store}>
    <Suspense>
      <AppWithCallbackAfterRender />
    </Suspense>
  </Provider>
);
